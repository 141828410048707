import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";
import { detect } from "detect-browser";
import { mobileModel } from 'react-device-detect'
const browser = detect();
console.log(browser, mobileModel);


const LoginService = {
  /**
   * calling login api
   * @param loginId user userName
   * @param password user password
   **/

  login: async (loginId, password) => {
    const { baseUrl, login } = ApiConfig;

    const url = baseUrl + login;

    const params = JSON.stringify({
      loginId: loginId,
      password: password,
      deviceId: "",
      deviceType: "",
      plateformVersion: `${browser.os}`,
      browserType: `${browser.name}`,
      browserVersion: `${browser.version}`,
    });

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });

    const loginServicePromise = await Fetch(
      url,
      "POST",
      headers,
      params,
      "services_LoginService",
      true
    );
    return loginServicePromise;
  },

  sentEmail: async (emailId) => {
    const { baseUrl, forgotPassword } = ApiConfig;

    const url = baseUrl + forgotPassword;

    const params = JSON.stringify({
      emailId: emailId,
    });

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });

    const sentEmailServicePromise = await Fetch(
      url,
      "POST",
      headers,
      params,
      "services_sentEmailService",
      true
    );
    return sentEmailServicePromise;
  },

  resetLinkPassword: async (data) => {
    const { baseUrl, resetPassword } = ApiConfig;

    const url = baseUrl + resetPassword;

    const params = JSON.stringify({
      token: `${data.token}`,
      password: `${data.password}`,
    });

    const headers = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    });

    const resetPasswordServicePromise = Fetch(
      url,
      "POST",
      headers,
      params,
      "services_resetPasswordService",
      true
    );
    return resetPasswordServicePromise;
  },
};

export default LoginService;
