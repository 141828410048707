import React from "react";
import { Row, Col } from "reactstrap";

const HealthInfoReport = (props) => {
  return (
    <Row className="pt-8px pb-8px bg-white br-4px no-margin">
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4px pb-4px">
        <p className="mb-0 internal-section__title opensans-semiBold title-theme-color">
          HEALTH INFO SUMMARY
        </p>
      </Col>
      {/* -------------RIGHT EYE-------------- */}
      <Col className="mt-1">
        <p className="mb-1">PHYSICAL EXAMINATION</p>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4px">
        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <p className="p-medium mb-0 internal-section__title title-suvaGrey">
              Height (cm)
            </p>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1} className="no-mobile-plr">
            <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
              -
            </p>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
              {props.height ? props.height : "NA"}
            </p>
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4px pb-4px">
        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <p className="p-medium mb-0 internal-section__title title-suvaGrey">
              Weight (Kg)
            </p>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1} className="no-mobile-plr">
            <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
              -
            </p>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
              {props.weight ? props.weight : "NA"}
            </p>
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4px pb-4px">
        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <p className="p-medium mb-0 internal-section__title title-suvaGrey">
              Blood Pressure (mm/Hg)
            </p>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1} className="no-mobile-plr">
            <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
              -
            </p>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
              {props.bloodPressure ? props.bloodPressure : "NA"}
            </p>
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4px pb-4px">
        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <p className="p-medium mb-0 internal-section__title title-suvaGrey">
              Blood Sugar (mg/dl)
            </p>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1} className="no-mobile-plr">
            <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
              -
            </p>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
              {props.bloodSugar ? props.bloodSugar : "NA"}
            </p>
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4px pb-4px">
        <p className="mb-0 mt-2">SYSTEMIC HISTORY</p>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4px pb-4px">
        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <p className="p-medium mb-0 internal-section__title title-suvaGrey">
              Hypertension
            </p>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1} className="no-mobile-plr">
            <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
              -
            </p>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
              {props.hypertension ? props.hypertension : "NA"}
            </p>
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4px pb-4px">
        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <p className="p-medium mb-0 internal-section__title title-suvaGrey">
              Diabetes
            </p>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1} className="no-mobile-plr">
            <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
              -
            </p>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
              {props.diabetes ? props.diabetes : "NA"}
            </p>
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4px pb-4px">
        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <p className="p-medium mb-0 internal-section__title title-suvaGrey">
              Duration
            </p>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1} className="no-mobile-plr">
            <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
              -
            </p>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
              {props.duration ? props.duration : "NA"}
            </p>
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4px pb-4px">
        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <p className="p-medium mb-0 internal-section__title title-suvaGrey">
              Family History
            </p>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1} className="no-mobile-plr">
            <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
              -
            </p>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
              {props.familyHistory ? props.familyHistory : "NA"}
            </p>
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4px pb-4px">
        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <p className="p-medium mb-0 internal-section__title title-suvaGrey">
              Medication
            </p>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1} className="no-mobile-plr">
            <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
              -
            </p>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
              {props.medication ? props.medication : "NA"}
            </p>
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4px pb-4px">
        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <p className="p-medium mb-0 internal-section__title title-suvaGrey">
              Medication Type
            </p>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1} className="no-mobile-plr">
            <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
              -
            </p>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
              {props.medicationType ? props.medicationType : "NA"}
            </p>
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4px pb-4px">
        <Row>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            <p className="p-medium mb-0 internal-section__title title-suvaGrey">
              Other comorbidities
            </p>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1} className="no-mobile-plr">
            <p className="p-medium mb-0 internal-section__title title-suvaGrey fw-800">
              -
            </p>
          </Col>
          <Col
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            className="d-flex flex-wrap justify-content-end"
          >
            {props.coMorbidities.length > 0 ? (
              props.coMorbidities.map((item, index) => (
                <p
                  className="p-medium mb-0 internal-section__title title-black fw-800 align-right"
                  key={index}
                >
                  {item.label}
                  <font className="pr-4px">{`${
                    props.coMorbidities.length - 1 === index ? "" : ","
                  }`}</font>
                </p>
              ))
            ) : (
              <p className="p-medium mb-0 internal-section__title title-black fw-800 align-right">
                NA
              </p>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default HealthInfoReport;
