import React, { useState } from "react";

import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  InputGroupText,
  InputGroupAddon,
  Input,
  InputGroup,
} from "reactstrap";
import XlsExport from "xlsexport";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import {
  dataTablesStyledTheme,
  dataTablesMobileStyledTheme,
} from "../../utils/dataTablesStyledTheme";
import Loader from "../../customComponents/Loader/Loader";
import Strip from "../../customComponents/Strip/Strip";
import applicationConstants from "../../constants/applicationContants";

const TablesColumns = [
  {
    name: "File Name",
    selector: "fileName",
    sortable: false,
    grow: 2.5,
  },
  {
    name: "Type",
    selector: "dilationType",
    sortable: false,
  },
  {
    name: "Device Name",
    selector: "deviceName",
    sortable: false,
    grow: 1.5,
  },
  {
    name: "Client",
    selector: "browserName",
    sortable: false,
    cell: (row) => <div>{row.browserName ? row.browserName : "MAPP"}</div>,
  },
  {
    name: "Platform/OS",
    selector: "platForm",
    sortable: false,
    cell: (row) => (
      <div>
        {row.platForm == "iOS" ? (
          <p className="mb-0">{row.platForm}</p>
        ) : (
          <p className="mb-0 text-capitalize">{row.platForm}</p>
        )}
      </div>
    ),
  },
  {
    name: "Device Id",
    selector: "deviceId",
    sortable: false,
    grow: 1.5,
  },
];

function downloadEXcel(array) {
  var xls = new XlsExport([...array], "device_information");
  xls.exportToXLS("device_information.xls");
}

const Export = ({ onExportExcel }) => {
  return (
    <div>
      <Button
        onClick={() => onExportExcel()}
        className="custom-theme-btn custom-btn--small mb-1 fs-12px lgrey-btn"
      >
        Export Excel
      </Button>
    </div>
  );
};

const MoreInformation = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [patientName, setPatientName] = useState('');
  const [campId, setCampId] = useState('');

  const handleExport = React.useMemo(
    () => <Export onExportExcel={() => downloadEXcel(data)} />,
    [data]
  );

  React.useEffect(() => {
    handleInformation();
  }, [props]);

  const handleInformation = () => {
    setIsLoading(true);
    if (props.data) {
      let LEPostItem = [];
      let LEPreItem = [];
      let REPostItem = [];
      let REPreItem = [];
      if (
        props.data.LEPostDeviceId != "null" &&
        props.data.LEPostDeviceId != "[]" &&
        props.data.LEPostDeviceId != "" &&
        props.data.LEPostDeviceId != null
      ) {
        if (props.data.LEPostDeviceId.length > 0) {
          let LEPost = {};
          props.data.LEPostDeviceId.map((item) => {
            LEPost = {
              patientName: props.data.patientName,
              campId: props.data.campId,
              deviceId: item.deviceId ? item.deviceId : "",
              deviceName:
                item.deviceName.constructor !== Object &&
                item.deviceName != "undefined" &&
                item.deviceName != undefined
                  ? item.deviceName
                  : "",
              platForm: item.platForm ? item.platForm : "",
              browserName:
                item.browserName != "undefined" && item.browserName != undefined
                  ? item.browserName
                  : "",
              fileName: item.fileName,
              dilationType: item.dilationType,
            };
            LEPostItem.push(LEPost);
          });
        }
      }
      if (
        props.data.LEPreDeviceId != "null" &&
        props.data.LEPreDeviceId != "[]" &&
        props.data.LEPreDeviceId != "" &&
        props.data.LEPreDeviceId != null
      ) {
        if (props.data.LEPreDeviceId.length > 0) {
          let LEPre = {};
          props.data.LEPreDeviceId.map((item) => {
            LEPre = {
              patientName: props.data.patientName,
              campId: props.data.campId,
              deviceId: item.deviceId ? item.deviceId : "",
              deviceName:
                item.deviceName.constructor !== Object &&
                item.deviceName != "undefined" &&
                item.deviceName != undefined
                  ? item.deviceName
                  : "",
              platForm: item.platForm ? item.platForm : "",
              browserName:
                item.browserName != "undefined" && item.browserName != undefined
                  ? item.browserName
                  : "",
              fileName: item.fileName,
              dilationType: item.dilationType,
            };
            LEPreItem.push(LEPre);
          });
        }
      }
      if (
        props.data.REPostDeviceId != "null" &&
        props.data.REPostDeviceId != "[]" &&
        props.data.REPostDeviceId != "" &&
        props.data.REPostDeviceId != null
      ) {
        if (props.data.REPostDeviceId.length > 0) {
          let REPost = {};
          props.data.REPostDeviceId.map((item) => {
            REPost = {
              patientName: props.data.patientName,
              campId: props.data.campId,
              deviceId: item.deviceId ? item.deviceId : "",
              deviceName:
                item.deviceName.constructor !== Object &&
                item.deviceName != "undefined" &&
                item.deviceName != undefined
                  ? item.deviceName
                  : "",
              platForm: item.platForm ? item.platForm : "",
              browserName:
                item.browserName != "undefined" && item.browserName != undefined
                  ? item.browserName
                  : "",
              fileName: item.fileName,
              dilationType: item.dilationType,
            };
            REPostItem.push(REPost);
          });
        }
      }
      if (
        props.data.REPreDeviceId != "null" &&
        props.data.REPreDeviceId != "[]" &&
        props.data.REPreDeviceId != "" &&
        props.data.REPreDeviceId != null
      ) {
        if (props.data.REPreDeviceId.length > 0) {
          let REPre = {};
          props.data.REPreDeviceId.map((item) => {
            REPre = {
              patientName: props.data.patientName,
              campId: props.data.campId,
              deviceId: item.deviceId ? item.deviceId : "",
              deviceName:
                item.deviceName.constructor !== Object &&
                item.deviceName != "undefined" &&
                item.deviceName != undefined
                  ? item.deviceName
                  : "",
              platForm: item.platForm ? item.platForm : "",
              browserName:
                item.browserName != "undefined" && item.browserName != undefined
                  ? item.browserName
                  : "",
              fileName: item.fileName,
              dilationType: item.dilationType,
            };
            REPreItem.push(REPre);
          });
        }
      }
      setData([...LEPostItem, ...LEPreItem, ...REPostItem, ...REPreItem]);
      setFilterData([...LEPostItem, ...LEPreItem, ...REPostItem, ...REPreItem]);
    }
    setIsLoading(false);
  };

  const onChange = async (event) => {
    setFilterText(event.target.value);
    await handlefilterData(event.target.value);
  };

  const handlefilterData = async (filterText) => {
    const filteredItems = await filterData.filter(
      (item) =>
        (item.browserName &&
          item.browserName.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.deviceId &&
          item.deviceId.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.dilationType &&
          item.dilationType.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.fileName &&
          item.fileName.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.platForm &&
          item.platForm.toLowerCase().includes(filterText.toLowerCase()))
    );
    if (filteredItems.length >= 0) {
      setData([...filteredItems]);
    } else {
      setData([...filterData]);
    }
  };

  const handleClear = () => {
    setData([...filterData]);
    setFilterText("");
  };

  return (
    <Strip
      id="tst-more-information"
      className="strip strip-no-padding"
      containerType="container-fluid"
    >
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Modal
            isOpen={props.isModal}
            toggle={props.toggle}
            className="modal-container modal-xl"
          >
            <div className="modal-header-container">
              <Row className="no-margin align-items-center">
                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                  <p className="p-large internal-page__title align-left title-white">
                    Device Information
                  </p>
                </Col>
                <Col
                  xs={2}
                  sm={2}
                  md={2}
                  lg={2}
                  xl={2}
                  className="text-right cursor-pointer"
                  onClick={props.toggle}
                >
                  <FontAwesomeIcon icon={faTimes} size="1x" color={"#fff"} />
                </Col>
              </Row>
            </div>
            <ModalBody className="modal-content-container">
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Row className="justify-content-end mb-2">
                    <Col xs={10} sm={10} md={8} lg={5} xl={4}>
                      <InputGroup>
                        <Input
                          placeholder="Search"
                          name="filterText"
                          value={filterText}
                          onChange={onChange}
                        />
                        <InputGroupAddon
                          addonType="append"
                          onClick={handleClear}
                        >
                          <InputGroupText className="cursor-pointer">
                            <FontAwesomeIcon
                              icon={filterText ? faTimes : faSearch}
                              color="white"
                            />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </Row>
                </Col>
                {data.length > 0 ? (
                  <Col
                    xs={12}
                    ms={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="m-auto device_info"
                  >
                    <DataTable
                      title=""
                      columns={TablesColumns}
                      data={data}
                      pagination
                      className="data-table"
                      persistTableHead={false}
                      customStyles={
                        props.windowWidth >
                        applicationConstants.mobileBreakPoint
                          ? dataTablesStyledTheme
                          : dataTablesMobileStyledTheme
                      }
                      actions={handleExport}
                    />{" "}
                  </Col>
                ) : (
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="no-mobile--padding"
                  >
                    <div className="br-light-smoke p-5 br-4px">
                      {!isLoading ? (
                        <p className="mb-0 align-center internal-page__title">
                          No Data Found
                        </p>
                      ) : (
                        <Loader isLoader={false} />
                      )}
                    </div>
                  </Col>
                )}
              </Row>
              {isLoading ? <Loader isLoader={false} /> : null}
            </ModalBody>
          </Modal>
        </Col>
      </Row>
    </Strip>
  );
};

export default MoreInformation;
