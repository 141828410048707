import React, { Component } from "react";
import windowSize from "react-window-size";
import PropTypes from "prop-types";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

function ImageZoom(props) {
  return (
    <TransformWrapper
      defaultScale={1}
      defaultPositionX={200}
      defaultPositionY={100}
    >
      <TransformComponent>
        <div className={`${props.imgContainer}`}>
          <img src={props.src} className="zoom-img-item" />
        </div>
      </TransformComponent>
    </TransformWrapper>
  );
}

ImageZoom.defaultProps = {
  imgContainer: "",
};

ImageZoom.propTypes = {
  imgContainer: PropTypes.string,
};

export default windowSize(ImageZoom);
