import React from "react";
import RenderPage from "../../../customComponents/RenderPage/RenderPage";
import Advice from "../../../customComponents/Advice/Advice";
import applicationConstants from "../../../constants/applicationContants";


class AtTeleAdvice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adviceType: applicationConstants.teleAdvice,
      title: applicationConstants.AtTeleAdvice,
    };
  }
 
  render() {
    return (
      <RenderPage
      id="registration-page"
      className="render-page"
      containerType="container-fluid"
    >
      <Advice title={this.state.title} adviceType={this.state.adviceType} />
    </RenderPage>
    );
  }
}
export default AtTeleAdvice;
