import React from "react";
import { Row, Col } from "reactstrap";

class ImageList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title ? props.title : "",
      dilationImage: props.dilationImage ? props.dilationImage : "",
    };
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (this.props.dilationImage.length != prevProps.dilationImage.length) {
      this.setState({
        dilationImage: this.props.dilationImage,
        title: this.props.title,
      });
    }
    return null
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      this.setState({
        dilationImage: this.props.dilationImage,
        title: this.props.title,
      });
    }
  }

  render() {
    return (
      <Row className="no-margin">
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 ">
            <p className="mb-0 internal-page__title title-theme-color align-left">
              {this.state.title}
            </p>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="px-0  mb-2 br-light-smoke br-4px"
          >
            <div className="d-flex img-view-container scoll-bar">
              {this.state.dilationImage.length > 0 ? (
                <div className="d-flex">
                  {this.state.dilationImage.map((item, index) => (
                    <div className="img-container" key={index}>
                      {item.mime === "image/jpeg" || item.mime === 'image/png' ? (
                        <img
                          src={`${item.signedUrl ? item.signedUrl : item.path}`}
                           onClick={() => this.props.handlePreviewItem(item)}
                          className="cursor-pointer"
                        />
                      ) : (
                        <video
                          width="100%"
                          height="100%"
                          controls
                          //onClick={() => this.props.handlePreviewImage(item)}
                        >
                          <source
                            src={`${
                              item.signedUrl ? item.signedUrl : item.path
                            }`}
                            type="video/mp4"
                          />
                        </video>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="img-container">
                  <p className="mb-0 internal-page__title">No Media</p>
                </div>
              )}
            </div>
          </Col>
        </Col>  
      </Row>
    );
  }
}
export default ImageList;
