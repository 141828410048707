import React from "react";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
  faArrowCircleLeft,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function BackArrow (props) {
    return (
      <Row className={`${props.className}`}>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="bg-theme-color br-top-lr-4px pt-12px pb-12px bg-org"
        >
          <Row className="no-margin align-items-center">
            <Col
              xs={2}
              sm={2}
              md={1}
              lg={1}
              xl={1}
              className="pr-0 d-flex cursor-pointer back-arrow"
              onClick={() => props.handleBackArrow()}
            >
              <FontAwesomeIcon
                icon={faArrowCircleLeft}
                color={`#fff`}
                size="2x"
              />
            </Col>
            <Col
              xs={`${props.isQuick ? 8 : 10}`}
              sm={`${props.isQuick ? 8 : 10}`}
              md={`${props.isQuick ? 8 : 10}`}
              lg={`${props.isQuick ? 10 : 11}`}
              xl={`${props.isQuick ? 10 : 11}`}
              className="px-0"
            >
              <p className="p-large mb-0 internal-page__title title-white align-center">
                {props.title}
              </p>
            </Col>
            {props.isQuick ? (
              <Col
                xs={2}
                sm={2}
                md={3}
                lg={1}
                xl={1}
                className="d-flex justify-content-end cursor-pointer"
                onClick={() => props.handleQuickHelp()}
              >
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  color={`#fff`}
                  size="2x"
                />
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>
    );
}

BackArrow.propTypes = {
  className: PropTypes.string,
  pathName: PropTypes.string,
  isQuick: PropTypes.bool,
};

BackArrow.defaultProps = {
  className: "",
  pathName: "Dashboard",
  isQuick: false,
};

export default withRouter(BackArrow);
