import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Row, Col, Button } from "reactstrap";
import ReactToPrint from "react-to-print";
import RenderPage from "../../customComponents/RenderPage/RenderPage";
import Strip from "../../customComponents/Strip/Strip";
import BackArrow from "../../customComponents/BackArrow/BackArrow";
import { userRoleData } from "../.../../../utils/dropdownData";
import ReportPrint from "../../customComponents/ReportPrint/ReportPrint";
import ReportDetails from "../../customComponents/ReportPrint/ReportDetails";

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roleId: "",
    };
  }
  async componentDidMount() {
    const roleId = await localStorage.getItem("roleId");
    this.setState({
      roleId: roleId,
    });
  }
  handleBackArrow = () => {
    this.props.history.push("/PatientList");
  };

  render() {
    return (
      <RenderPage
        id="advice-page"
        className="render-page"
        containerType="container-fluid"
      >
        <Strip
          id="tst-adivce"
          className="strip strip-no-padding"
          containerType="container-fluid"
        >
          <Row className="no-margin mb-4">
            <Col xs={12} sm={12} md={10} lg={10} xl={6} className="m-auto">
              <BackArrow
                title={`Report`}
                handleBackArrow={this.handleBackArrow}
              />
              <Row className="bg-light-white pd-12px br-4px">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Row className="no-margin">
                    <ReportDetails />
                  </Row>
                  <div style={{ display: "none" }}>
                    <ReportPrint ref={(el) => (this.componentRef = el)} />
                  </div>
                  {userRoleData[1].label === this.state.roleId ||
                  this.state.roleId === userRoleData[0].label ||
                  userRoleData[2].label === this.state.roleId ||
                  userRoleData[3].label === this.state.roleId ? (
                    <Row className="pt-8px pb-8px">
                      <Col>
                        <div>
                          <ReactToPrint
                            trigger={() => (
                              <Button className="btn btn-block custom-grey-btn">
                                PRINT
                              </Button>
                            )}
                            content={() => this.componentRef}
                          />
                        </div>
                      </Col>
                      <Col>
                        <Button
                          className="btn btn-block custom-theme-btn"
                          onClick={() => this.props.history.push("/Dashboard")}
                        >
                          CONTINUE
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    <Row className="pt-8px pb-8px">
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="no-mobile--padding">
                        <Link
                          to={`/ImagingAndGrading`}
                          // className={`d-flex justify-content-center`}
                        >
                          <Button
                            className="btn btn-block custom-theme-btn"
                            //onClick={() => this.handleClick("")}
                          >
                            {`IMAGING & GRADING`}
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Strip>
      </RenderPage>
    );
  }
}
export default withRouter(Report);
