import swal from "sweetalert";

function alertDangerMessage(message) {
  swal({
    title: "",
    text: message,
    icon: "warning",
    dangerMode: true,
    buttons: "OK",
    // timer: 3000,
  });
};

function alertSuccessMessage(message) {
  swal({
    title: "",
    text: message,
    icon: "success",
    className:"swal-modal-succuess-small",
    timer: 3000,
  });
}

function alertDangerConfirmMessage(message) {
  swal({
    title: "",
    text: message,
    icon: "warning",
    className:"swal-modal-warning-small",
    timer: 3000,
  });
}

export { alertDangerMessage, alertDangerConfirmMessage, alertSuccessMessage };
