import React from "react";
import { Row, Col, Input, Form, FormGroup, Label, Button } from "reactstrap";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
// import imagebase64 from "base64-img";
import axios from 'axios';
import { ApiConfig } from "../../../../api/apiConfig/apiConfig";
import {isMobile,isTablet,mobileModel,mobileVendor,browserName,osName } from "react-device-detect";
import PatientService from "../../../../api/services/PatientService";
import { YesOrNo, DRDataOne, DRDataTwo, unableToGradeData, imageQuadrant, userRoleData, MacularEdemaData, macEduma } from "../../utils/dropdownData";
import Strip from "../Strip/Strip";
import applicationConstants from "../../constants/applicationContants";
import PreviewImage from "./PreviewImage";
import Dilation from "./Dilation";
import CapturedImage from "./CapturedImage";
import { deleteItem, s3UploadFile } from "../../s3Services/S3Services";
import { AwsConfig } from "../../config/AwsConfig";
import { alertDangerConfirmMessage } from "../confirmationMessage/ConfirmationMessage";
import ConfirmationBackArrow from "../confirmationMessage/ConfirmationBackArrow";
import BackArrow from "../BackArrow/BackArrow";
import QuickHelpMessage from "../confirmationMessage/QuickHelpMessage";
import { DiagnosisAbbrevationInfo } from "../../utils/AbbrevationInfo";
import Loader from "../Loader/Loader";

var deleteItemArray = [];
var myVideos = [];
window.URL = window.URL || window.webkitURL;

class Diagnosis extends React.Component {
    constructor(props) {
        super(props);
        console.log("diagonisi",props);
        this.state = {
            patientId: "",
            patientName: "",
            mocDocPhId: "",
            visionCenterId: "",
            abnormalDisc: false,
            drChecked: false,
            // DrValue: -1,
            DrValueSingleSelect: -1,
            DrValueMultiSelect: -1,
            unableToGradeCheck: false,
            RDR: YesOrNo[0],
            MacEde:macEduma[0],
            preDilationMedia: [],
            postDilationMedia: [],
            unableToGrade: -1,
            comments: "",
            otherdiagnosis: "",
            diagnosisType: props.diagnosisType ? props.diagnosisType : "",
            imageType: props.imageType ? props.imageType : "",
            title: props.title ? props.title : "",
            modal: false,
            previewItem: "",
            previewItemAll:[],
            quadrant: -1,
            ImgdilationType:"",
            mediaPreSelect: [],
            mediaPostSelect: [],
            discardItems: [],
            deleteS3Obj: [],
            isEdit: false,
            isConfirmation: false,
            isReset: false,
            isQuickHelp: false,
            roleId: "",
            isLoading: false,
            isDuplicateImg: [],
            isCount: 0,
            isDiscard: true,
            // DrValueSingleSelect: [],
            // DrValueMultiSelect: [],
            timer: 0,
            campStatus: 0,
            duration: 0,
            isUpdate: false,
            base64Image: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    async componentDidMount() {
        const token = await localStorage.getItem("token");
        const patientId = await localStorage.getItem("patientId");
        const patientName = await localStorage.getItem("patientName");
        const roleId = await localStorage.getItem("roleId");
        const campStatus = await localStorage.getItem("campStatus");
        if (patientId) {
            this.setState({
                isLoading: true,
                campStatus: campStatus,
            });
            PatientService.getPatientBasedOnId(token, patientId).then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                let data = result.data;
                                console.log("getpatient",data);
                                if (data) {
                                    this.setState({
                                        campStatus: data.campId || data.visionCentreId ? "1" : "0",
                                        mocDocPhId:  data.mocDocPhid,
                                        visionCenterId:  data.visionCenterName,
                                    });
                                } 
                                if(this.state.diagnosisType === applicationConstants.optoRE) {
                                    if (data.optoREDiag) {
                                        this.setState({
                                            patientId: patientId,
                                            patientName: data.registerInfo.patientName,
                                            abnormalDisc:
                                            data.optoREDiag.REAbnormalDisc === 1 ? true : false,
                                            // RDR: YesOrNo[data.optoREDiag.RERDR],
                                            comments: data.optoREDiag.REOther,
                                            otherdiagnosis: data.optoREDiag.REOtherDiagnosis,
                                            duration: parseInt(data.optoREDiag.REDuration),
                                        });
                                        if (
                                            data.optoREDiag.REPreDilationImaging !== "undefined" &&
                                            data.optoREDiag.REPreDilationImaging !== "null"
                                        ) {
                                            const preDilationImage = JSON.parse( data.optoREDiag.REPreDilationImaging );

                                            if (preDilationImage !== "[]" && preDilationImage !== "") {
                                                this.setState({
                                                    preDilationMedia: preDilationImage,
                                                });
                                            }
                                        }

                                        if (data.optoREDiag.REPostDilationImaging !== "undefined" && data.optoREDiag.REPostDilationImaging !== "null" ) {
                                            const postDilationImage = JSON.parse(data.optoREDiag.REPostDilationImaging);
                                            
                                            if(postDilationImage !== "[]" && postDilationImage !== "") {
                                                this.setState({
                                                    postDilationMedia: postDilationImage,
                                                });
                                            }
                                        }

                                        if (data.optoREDiag.REDR === "-1" || data.optoREDiag.REDR === -1 ) {
                                            
                                            if ( data.optoREDiag.REUnableToGrade !== "-1" && data.optoREDiag.REUnableToGrade !== -1) {
                                                
                                                this.setState({
                                                    drChecked: false, 
                                                    DrValueSingleSelect: -1,
                                                    DrValueMultiSelect: -1,
                                                    unableToGradeCheck: true,
                                                    unableToGrade:
                                                    unableToGradeData[
                                                        data.optoREDiag.REUnableToGrade
                                                    ],
                                                    RDR: -1,
                                                });
                                            } else {
                                                this.setState({
                                                    drChecked: false,
                                                    unableToGrade: -1, 
                                                    DrValueSingleSelect: -1,
                                                    DrValueMultiSelect: -1,
                                                    unableToGradeCheck: false,
                                                    RDR: -1,
                                                });
                                            }
                                        } else {
                                            const DRArrayListTwo = [];
                                            const DRItem = JSON.parse(data.optoREDiag.REDR);
                                            if (DRItem) {
                                                DRItem.map((item) => {
                                                    if (item === 0 || item === 1 || item === 2 || item === 3 || item === 4 ) {
                                                        this.setState({
                                                            DrValueSingleSelect: DRDataOne[item],
                                                        });
                                                    }
                                                    if (item === 5 || item === 6 || item === 7) {
                                                        DRArrayListTwo.push(
                                                            DRDataTwo[item === 5 ? 0 : item === 6 ? 1 : item === 7 ? 2 : null]
                                                        );
                                                    }
                                                });
                                            }
                                            this.setState({
                                                drChecked: true,
                                                DrValueMultiSelect: DRArrayListTwo,
                                                unableToGradeCheck: false,
                                                unableToGrade: -1,
                                                RDR: YesOrNo[data.optoREDiag.RERDR],
                                            });
                                        }
                                    } else {
                                        this.setState({
                                            drChecked: false,
                                            unableToGrade: -1,
                                            // DrValue: -1,
                                            DrValueSingleSelect: -1,
                                            DrValueMultiSelect: -1,
                                            unableToGradeCheck: false,
                                            RDR: -1,
                                        });
                                    }
                                }
                                
                                //OptoLE Diagnosis
                                if ( this.state.diagnosisType === applicationConstants.optoLE ) {
                                    if (data.optoLEDiag) {
                                        this.setState({
                                            patientId: patientId,
                                            patientName: data.registerInfo.patientName,
                                            abnormalDisc: data.optoLEDiag.LEAbnormalDisc,
                                            // RDR:
                                            //   data.optoLEDiag.LERDR != null
                                            //     ? YesOrNo[data.optoLEDiag.LERDR]
                                            //     : YesOrNo[0],
                                            comments: data.optoLEDiag.LEOther,
                                            otherdiagnosis: data.optoLEDiag.LEOtherDiagnosis,
                                            duration: parseInt(data.optoLEDiag.LEDuration),
                                        });

                                        if ( data.optoLEDiag.LEPreDilationImaging !== "undefined" && data.optoLEDiag.LEPreDilationImaging !== "null" ) {
                                            const preDilationImage = JSON.parse(data.optoLEDiag.LEPreDilationImaging);

                                            if ( preDilationImage !== "[]" && preDilationImage !== "" ) {
                                                this.setState({
                                                    preDilationMedia: preDilationImage,
                                                });
                                            }
                                        }

                                        if (data.optoLEDiag.LEPostDilationImaging !== "undefined" && data.optoLEDiag.LEPostDilationImaging !== "null" ) {
                                            const postDilationImage = JSON.parse(data.optoLEDiag.LEPostDilationImaging);
                                            if(postDilationImage !== "[]" && postDilationImage !== "" ) {
                                                this.setState({
                                                    postDilationMedia: postDilationImage,
                                                });
                                            }
                                        }

                                        if (data.optoLEDiag.LEDR === "-1" || data.optoLEDiag.LEDR === -1) {
                                            if (data.optoLEDiag.LEUnableToGrade !== "-1" && data.optoLEDiag.LEUnableToGrade !== -1) {
                                                this.setState({
                                                    drChecked: false, 
                                                    DrValueSingleSelect: -1,
                                                    DrValueMultiSelect: -1,
                                                    unableToGradeCheck: true,
                                                    unableToGrade:
                                                    unableToGradeData[
                                                        data.optoLEDiag.LEUnableToGrade
                                                    ],
                                                    RDR: -1,
                                                });
                                            } else {
                                                this.setState({
                                                    drChecked: false,
                                                    unableToGrade: -1, 
                                                    DrValueSingleSelect: -1,
                                                    DrValueMultiSelect: -1,
                                                    unableToGradeCheck: false,
                                                    RDR: -1,
                                                });
                                            }
                                        } else {
                                            const DRArrayListTwo = [];
                                            const DRItem = JSON.parse(data.optoLEDiag.LEDR);
                                            if (DRItem) {
                                                DRItem.map((item) => {
                                                    if ( item === 0 || item === 1 || item === 2 || item === 3 || item === 4 ) {
                                                        this.setState({
                                                            DrValueSingleSelect: DRDataOne[item],
                                                        });
                                                    }
                                                    if (item === 5 || item === 6 || item === 7) {
                                                        DRArrayListTwo.push(
                                                            DRDataTwo[item === 5 ? 0 : item === 6 ? 1 : item === 7 ? 2 : null]
                                                        );
                                                    }
                                                });
                                            }
                                            this.setState({
                                                drChecked: true,
                                                DrValueMultiSelect: DRArrayListTwo,
                                                unableToGradeCheck: false,
                                                unableToGrade: -1,
                                                RDR: data.optoLEDiag.LERDR != null ? YesOrNo[data.optoLEDiag.LERDR] : -1,
                                            });
                                        }
                                    } else {
                                        this.setState({
                                            drChecked: false,
                                            unableToGrade: -1, 
                                            DrValueSingleSelect: -1,
                                            DrValueMultiSelect: -1,
                                            unableToGradeCheck: false,
                                            RDR: -1,
                                        });
                                    }
                                }

                                //CampDR RE Diagnosis
                                if ( this.state.diagnosisType === applicationConstants.AtCampRE ) {
                                    if (data.campDrREDiag) {
                                        this.setState({
                                            patientId: patientId,
                                            patientName: data.registerInfo.patientName,
                                            abnormalDisc: data.campDrREDiag.REAbnormalDisc,
                                            //RDR: YesOrNo[data.campDrREDiag.RERDR],
                                            comments: data.campDrREDiag.REOther,
                                            otherdiagnosis: data.campDrREDiag.REOtherDiagnosis,
                                        });

                                        if(data.campDrREDiag.REDR === "-1" || data.campDrREDiag.REDR === 1 ) {
                                            
                                            if(data.campDrREDiag.REUnableToGrade !== "-1" && data.campDrREDiag.REUnableToGrade !== -1 ) {
                                                this.setState({
                                                    drChecked: false, 
                                                    DrValueSingleSelect: -1,
                                                    DrValueMultiSelect: -1,
                                                    unableToGradeCheck: true,
                                                    unableToGrade:
                                                    unableToGradeData[
                                                        data.campDrREDiag.REUnableToGrade
                                                    ],
                                                    RDR: -1,
                                                });
                                            } else {
                                                this.setState({
                                                    drChecked: false,
                                                    unableToGrade: -1, 
                                                    DrValueSingleSelect: -1,
                                                    DrValueMultiSelect: -1,
                                                    unableToGradeCheck: false,
                                                    RDR: -1,
                                                });
                                            }
                                        } else {
                                            const DRArrayListTwo = [];
                                            const DRItem = JSON.parse(data.campDrREDiag.REDR);
                                            if (DRItem) {
                                                DRItem.map((item) => {
                                                    if ( item === 0 || item === 1 || item === 2 || item === 3 || item === 4 ) {
                                                        this.setState({
                                                            DrValueSingleSelect: DRDataOne[item],
                                                        });
                                                    }
                                                    if (item === 5 || item === 6 || item === 7) {
                                                        DRArrayListTwo.push(
                                                            DRDataTwo[item === 5 ? 0 : item === 6 ? 1 : item === 7 ? 2 : null]
                                                        );
                                                    }
                                                });
                                            }
                                            this.setState({
                                                drChecked: true,
                                                DrValueMultiSelect: DRArrayListTwo,
                                                unableToGradeCheck: false,
                                                unableToGrade: -1,
                                                RDR: YesOrNo[data.campDrREDiag.RERDR],
                                            });
                                        }
                                    } else {
                                        this.setState({
                                            drChecked: false,
                                            unableToGrade: -1,
                                            // DrValue: -1,
                                            DrValueSingleSelect: -1,
                                            DrValueMultiSelect: -1,
                                            unableToGradeCheck: false,
                                            RDR: -1,
                                        });
                                    }

                                    if (data.optoREDiag) {
                                        if ( data.optoREDiag.REPreDilationImaging !== "undefined" && data.optoREDiag.REPreDilationImaging !== "null" ) {
                                            const preDilationImage = JSON.parse(
                                                data.optoREDiag.REPreDilationImaging
                                            );

                                            if (preDilationImage !== "[]" && preDilationImage !== "" ) {
                                                this.setState({
                                                    preDilationMedia: preDilationImage,
                                                });
                                            }
                                        }

                                        if ( data.optoREDiag.REPostDilationImaging !== "undefined" && data.optoREDiag.REPostDilationImaging !== "null" ) {
                                            const postDilationImage = JSON.parse(
                                                data.optoREDiag.REPostDilationImaging
                                            );

                                            if ( postDilationImage !== "[]" && postDilationImage !== "" ) {
                                                this.setState({
                                                    postDilationMedia: postDilationImage,
                                                });
                                            }
                                        }
                                    }
                                }
                  
                                // CampDR LE Diagnosis
                                if ( this.state.diagnosisType === applicationConstants.AtCampLE ) {
                                    if (data.campDrLEDiag) {
                                        this.setState({
                                            patientId: patientId,
                                            patientName: data.registerInfo.patientName,
                                            abnormalDisc: data.campDrLEDiag.LEAbnormalDisc,
                                            //  RDR: YesOrNo[data.campDrLEDiag.LERDR],
                                            comments: data.campDrLEDiag.LEOther,
                                            otherdiagnosis: data.campDrLEDiag.LEOtherDiagnosis,
                                        });

                                        if ( data.campDrLEDiag.LEDR === "-1" || data.campDrLEDiag.LEDR === 1 ) {
                                            if ( data.campDrLEDiag.LEUnableToGrade !== "-1" && data.campDrLEDiag.LEUnableToGrade !== -1 ) {
                                                this.setState({
                                                    drChecked: false, 
                                                    DrValueSingleSelect: -1,
                                                    DrValueMultiSelect: -1,
                                                    unableToGradeCheck: true,
                                                    unableToGrade:
                                                    unableToGradeData[
                                                        data.campDrLEDiag.LEUnableToGrade
                                                    ],
                                                    RDR: -1,
                                                });
                                            } else {
                                                this.setState({
                                                    drChecked: false,
                                                    unableToGrade: -1,
                                                    // DrValue: -1,
                                                    DrValueSingleSelect: -1,
                                                    DrValueMultiSelect: -1,
                                                    unableToGradeCheck: false,
                                                    RDR: -1,
                                                });
                                            }
                                        } else {
                                            const DRArrayListTwo = [];
                                            const DRItem = JSON.parse(data.campDrLEDiag.LEDR);
                                            if (DRItem) {
                                                DRItem.map((item) => {
                                                    if ( item === 0 || item === 1 || item === 2 || item === 3 || item === 4 ) {
                                                        this.setState({
                                                            DrValueSingleSelect: DRDataOne[item],
                                                        });
                                                    }
                                                    if (item === 5 || item === 6 || item === 7) {
                                                        DRArrayListTwo.push(
                                                            DRDataTwo[item === 5 ? 0 : item === 6 ? 1 : item === 7 ? 2 : null]
                                                        );
                                                    }
                                                });
                                            }
                                            this.setState({
                                                drChecked: true,
                                                DrValueMultiSelect: DRArrayListTwo,
                                                unableToGradeCheck: false,
                                                unableToGrade: -1,
                                                RDR: YesOrNo[data.campDrLEDiag.LERDR],
                                            });
                                        }
                                    } else {
                                        this.setState({
                                            drChecked: false,
                                            unableToGrade: -1,
                                            // DrValue: -1,
                                            DrValueSingleSelect: -1,
                                            DrValueMultiSelect: -1,
                                            unableToGradeCheck: false,
                                            RDR: -1,
                                        });
                                    }

                                    if (data.optoLEDiag) {
                                        if ( data.optoLEDiag.LEPreDilationImaging !== "undefined" && data.optoLEDiag.LEPreDilationImaging !== "null" ) {
                                            const preDilationImage = JSON.parse( data.optoLEDiag.LEPreDilationImaging );

                                            if ( preDilationImage !== "[]" && preDilationImage !== "" ) {
                                                this.setState({
                                                    preDilationMedia: preDilationImage,
                                                });
                                            }
                                        }

                                        if ( data.optoLEDiag.LEPostDilationImaging !== "undefined" && data.optoLEDiag.LEPostDilationImaging !== "null" ) {
                                            const postDilationImage = JSON.parse( data.optoLEDiag.LEPostDilationImaging );
                                            if ( postDilationImage !== "[]" && postDilationImage !== "" ) { 
                                                this.setState({ 
                                                    postDilationMedia: postDilationImage,
                                                });
                                            }
                                        }
                                    }
                                }

                                //TeleDR RE Diagnosis
                                if ( this.state.diagnosisType === applicationConstants.AtTeleRE ) {
                                    if (data.teleDrREDiag) {
                                        this.setState({
                                            patientId: patientId,
                                            patientName: data.registerInfo.patientName,
                                            abnormalDisc: data.teleDrREDiag.REAbnormalDisc,
                                            //RDR: YesOrNo[data.teleDrREDiag.RERDR],
                                            comments: data.teleDrREDiag.REOther,
                                            otherdiagnosis: data.teleDrREDiag.REOtherDiagnosis,
                                        });

                                        if ( data.teleDrREDiag.REDR === "-1" || data.teleDrREDiag.REDR === -1 ) {
                                            if ( data.teleDrREDiag.REUnableToGrade !== "-1" && data.teleDrREDiag.REUnableToGrade !== -1 ) {
                                                this.setState({
                                                    drChecked: false,
                                                    // DrValue: -1,
                                                    DrValueSingleSelect: -1,
                                                    DrValueMultiSelect: -1,
                                                    unableToGradeCheck: true,
                                                    unableToGrade:
                                                    unableToGradeData[
                                                        data.teleDrREDiag.REUnableToGrade
                                                    ],
                                                    RDR: -1,
                                                });
                                            } else {
                                                this.setState({
                                                    drChecked: false,
                                                    unableToGrade: -1,
                                                    // DrValue: -1,
                                                    DrValueSingleSelect: -1,
                                                    DrValueMultiSelect: -1,
                                                    unableToGradeCheck: false,
                                                    RDR: -1,
                                                });
                                            }
                                        } else {
                                            const DRArrayListTwo = [];
                                            const DRItem = JSON.parse(data.teleDrREDiag.REDR);
                                            if (DRItem) {
                                                DRItem.map((item) => {
                                                    if ( item === 0 || item === 1 || item === 2 || item === 3 || item === 4 ) {
                                                        this.setState({
                                                            DrValueSingleSelect: DRDataOne[item],
                                                        });
                                                    }
                                                    if (item === 5 || item === 6 || item === 7) {
                                                        DRArrayListTwo.push(
                                                            DRDataTwo[item === 5 ? 0 : item === 6 ? 1 : item === 7 ? 2 : null]
                                                        );
                                                    }
                                                });
                                            }
                                            this.setState({
                                                drChecked: true,
                                                DrValueMultiSelect: DRArrayListTwo,
                                                unableToGradeCheck: false,
                                                unableToGrade: -1,
                                                RDR: YesOrNo[data.teleDrREDiag.RERDR],
                                            });
                                        }

                                    } else {
                                        this.setState({
                                            drChecked: false,
                                            unableToGrade: -1,
                                            DrValueSingleSelect: -1,
                                            DrValueMultiSelect: -1,
                                            // DrValue: -1,
                                            unableToGradeCheck: false,
                                            RDR: -1,
                                        });
                                    }
                            
                                    if (data.optoREDiag) {
                                        if ( data.optoREDiag.REPreDilationImaging !== "undefined" && data.optoREDiag.REPreDilationImaging !== "null" ) {
                                            const preDilationImage = JSON.parse(
                                                data.optoREDiag.REPreDilationImaging
                                            );

                                            if ( preDilationImage !== "[]" && preDilationImage !== "" ) {
                                                this.setState({
                                                    preDilationMedia: preDilationImage,
                                                });
                                            }
                                        }
                                        
                                        if ( data.optoREDiag.REPostDilationImaging !== "undefined" && data.optoREDiag.REPostDilationImaging !== "null" ) {
                                            const postDilationImage = JSON.parse(
                                                data.optoREDiag.REPostDilationImaging
                                            );
                                            if ( postDilationImage !== "[]" && postDilationImage !== "" ) {
                                                this.setState({
                                                    postDilationMedia: postDilationImage,
                                                });
                                            }
                                        }
                                    }
                                }
                        
                                //TeleDR LE Diagnosis
                                if ( this.state.diagnosisType === applicationConstants.AtTeleLE ) {
                                    if (data.teleDrLEDiag) {
                                        this.setState({
                                            patientId: patientId,
                                            patientName: data.registerInfo.patientName,
                                            abnormalDisc: data.teleDrLEDiag.LEAbnormalDisc,
                                            //RDR: YesOrNo[data.teleDrLEDiag.LERDR],
                                            comments: data.teleDrLEDiag.LEOther,
                                            otherdiagnosis: data.teleDrLEDiag.LEOtherDiagnosis,
                                        });

                                        if ( data.teleDrLEDiag.LEDR === "-1" || data.teleDrLEDiag.LEDR === -1 ) {
                                            if ( data.teleDrLEDiag.LEUnableToGrade !== -1 && data.teleDrLEDiag.LEUnableToGrade !== "-1" ) {
                                                this.setState({
                                                    drChecked: false,
                                                    // DrValue: -1,
                                                    DrValueSingleSelect: -1,
                                                    DrValueMultiSelect: -1,
                                                    unableToGradeCheck: true,
                                                    unableToGrade:
                                                    unableToGradeData[
                                                        data.teleDrLEDiag.LEUnableToGrade
                                                    ],
                                                    RDR: -1,
                                                });
                                            } else {
                                                this.setState({
                                                    drChecked: false,
                                                    unableToGrade: -1,
                                                    // DrValue: -1,
                                                    DrValueSingleSelect: -1,
                                                    DrValueMultiSelect: -1,
                                                    unableToGradeCheck: false,
                                                    RDR: -1,
                                                });
                                            }
                                        } else {
                                            const DRArrayListTwo = [];
                                            const DRItem = JSON.parse(data.teleDrLEDiag.LEDR);
                                            if (DRItem) {
                                                DRItem.map((item) => {
                                                    if ( item === 0 || item === 1 || item === 2 || item === 3 || item === 4 ) {
                                                        this.setState({
                                                            DrValueSingleSelect: DRDataOne[item],
                                                        });
                                                    }
                                                    if (item === 5 || item === 6 || item === 7) {
                                                        DRArrayListTwo.push(
                                                            DRDataTwo[item === 5 ? 0 : item === 6 ? 1 : item === 7 ? 2 : null]
                                                        );
                                                    }
                                                });
                                            }
                                            this.setState({
                                                drChecked: true,
                                                DrValueMultiSelect: DRArrayListTwo,
                                                unableToGradeCheck: false,
                                                unableToGrade: -1,
                                                RDR: YesOrNo[data.teleDrLEDiag.LERDR],
                                            });
                                        }
                                    } else {
                                        this.setState({
                                            drChecked: false,
                                            unableToGrade: -1,
                                            // DrValue: -1,
                                            DrValueSingleSelect: -1,
                                            DrValueMultiSelect: -1,
                                            unableToGradeCheck: false,
                                            RDR: -1,
                                        });
                                    }

                                    if (data.optoLEDiag) {
                                        if ( data.optoLEDiag.LEPreDilationImaging !== "undefined" && data.optoLEDiag.LEPreDilationImaging !== "null" ) {
                                            const preDilationImage = JSON.parse(
                                                data.optoLEDiag.LEPreDilationImaging
                                            );

                                            if (preDilationImage !== "[]" && preDilationImage !== "") {
                                                this.setState({
                                                    preDilationMedia: preDilationImage,
                                                });
                                            }
                                        }

                                        if ( data.optoLEDiag.LEPostDilationImaging !== "undefined" && data.optoLEDiag.LEPostDilationImaging !== "null" ) {
                                            const postDilationImage = JSON.parse(
                                                data.optoLEDiag.LEPostDilationImaging
                                            );
                                            if ( postDilationImage !== "[]" && postDilationImage !== "" ) {
                                                this.setState({
                                                    postDilationMedia: postDilationImage,
                                                });
                                            }
                                        }
                                    }
                                }

                                await setTimeout(() => {
                                    // this.getObjectListFromS3();
                                    this.getBase64ForS3();
                                }, 200);
                            } else {
                                alert(`${result.message}`);
                                await setTimeout(() => {
                                    this.setState({
                                    isLoading: false,
                                    });
                                }, 2000);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        await setTimeout(() => {
                            this.setState({
                            isLoading: false,
                            });
                        }, 2000);
                    }
                }
            }).catch((err) => {
                this.setState({
                    isLoading: false,
                });
                console.log(err);
            });
        }
        this.setState({
            patientId: patientId,
            patientName: patientName,
            roleId: roleId,
        });
        this.interval = setInterval(() => 
            this.setState({ timer: this.state.timer + 1 }),
            1000
        );
    }

  // getObjectListFromS3 = async () => {
  //   let list = [];
  //   let data = [];
  //   await getObjectList(
  //     `${this.state.patientId}/${this.state.diagnosisType.slice(-2)}`
  //   )
  //     .then(async (result) => {
  //       if (result.status === 200) {
  //         data = [
  //           ...this.state.preDilationMedia,
  //           ...this.state.postDilationMedia,
  //         ];
  //         list = [...result.data.Contents];
  //         for (var i = 0; i < list.length; i++) {
  //           let objIndex = data.findIndex((obj) => obj.objKey === list[i].Key);
  //           if (objIndex === -1) {
  //             await deleteItem(list[i].Key)
  //               .then(async (result) => {
  //                 if (result.status == 200) {
  //                   console.log(`Deleting file`);
  //                 }
  //               })
  //               .catch((err) => {
  //                 console.log(err);
  //               });
  //           }
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

    getBase64ForS3 = async () => {
        let items = [
            ...this.state.preDilationMedia,
            ...this.state.postDilationMedia,
        ];
        if (items.length > 0) {
            await this.asyncForEach(items);
        }
        this.setState({
            isLoading: false,
        });
    };

    asyncForEach = async (items) => {
        for (let i = 0; i < items.length; i++) {
            const item = await this.convertURLToBase64(items[i].signedUrl);
            if (item !== "") {
                let objArray = [];
                let obj = { path: item, isUpdate: false };
                objArray.push(obj);
                this.setState({
                    isDuplicateImg: [...this.state.isDuplicateImg, ...objArray],
                });
            }
        }
    };

    // convertURLToBase64(url) {
    //   return new Promise(async function (resolve, reject) {
    //     await imagebase64.requestBase64(`${url}`, function (err, res, body) {
    //       resolve(body);
    //     });
    //   });
    // }

    convertURLToBase64 = async (url) => {
        if(url!=undefined){        
            console.log("checking convertURLTurloBase64",url);
            const { baseUrl, getPatientImage } = ApiConfig;
            const apiURL=baseUrl+getPatientImage+`?imageURL=${url}`;
            this.setState({
                isLoading: true,
            });
            await axios.get(apiURL, { responseType: 'arraybuffer' }).then(res =>{
                if(res.data){
                    console.log("checking res",res);
                    // const base64 = Buffer.from(res.data, 'binary').toString('base64');
                    // this.setState({ base64Image: base64 });
                    // return base64;

                    const Buffer = require('buffer').Buffer;

                    const base64 = Buffer.from(res.data, 'binary').toString('base64');
                    this.setState({ base64Image: base64 });
                    this.setState({
                        isLoading: false,
                    });
                    return base64;
                }
            });
        }
    };


    handleChange(event) {
        event.preventDefault();
        this.setState({
            [event.target.name]: event.target.value,
            isEdit: true,
            isReset: false,
            isUpdate: this.state.patientId ? false : true,
        });
    }

    handleAbnormalDisc = () => {
        this.setState((prevState) => ({
            abnormalDisc: !prevState.abnormalDisc,
            isEdit: true,
            isReset: false,
            isUpdate: this.state.patientId ? false : true,
        }));
    };

    handleRDR = (item) => {
        this.setState({
            RDR: item,
            isEdit: true,
            isReset: false,
            isUpdate: this.state.patientId ? false : true,
        });
    };

    handleMacEde = (item) =>{
        this.setState({
            MacEde: item,
            isEdit: true,
            isReset: false,
            isUpdate: this.state.patientId ? false : true,
        })
    }

    handleGrade = async () => {
        if (this.state.unableToGradeCheck) {
            this.setState((prevState) => ({
                unableToGradeCheck: !prevState.unableToGradeCheck,
                drChecked: false,
                // DrValue: -1,
                DrValueSingleSelect: -1,
                DrValueMultiSelect: -1,
                isEdit: true,
                isReset: false,
                RDR: -1,
            }));
        } else {
            this.setState((prevState) => ({
                unableToGradeCheck: !prevState.unableToGradeCheck,
                drChecked: false,
                // DrValue: -1,
                DrValueSingleSelect: -1,
                DrValueMultiSelect: -1,
                //   unableToGradeCheck: -1,
                isEdit: true,
                isReset: false,
                RDR: -1,
            }));
        }
    };

    handleUnableToGrade = async (item) => {
        await this.setState({
            unableToGrade: item,
            isEdit: true,
            isReset: false,
            RDR: -1,
            isUpdate: this.state.patientId ? false : true,
        });
    };

    handleDrvalue = async (item) => {
        if (item) {
            if ( item.value === 1 || item.value === 2 || item.value === 3 || this.state.DrValueMultiSelect.value === 5 ) { 
                this.setState({
                    RDR: YesOrNo[1],
                });
            } else {
                this.setState({
                    RDR: YesOrNo[0],
                });
            }
        } else {
            await this.setState({
                RDR: -1,
            });
        }
        await this.setState({
            DrValueSingleSelect: item,
            isEdit: true,
            isReset: false,
            isUpdate: this.state.patientId ? false : true,
        });
    };

    handleDrMultivalue = async (item) => {
        if (this.state.DrValueSingleSelect) {
            if (
                this.state.DrValueSingleSelect.value === 1 ||
                this.state.DrValueSingleSelect.value === 2 ||
                this.state.DrValueSingleSelect.value === 3 ||
                item.value === 5
            ) {
                this.setState({
                    RDR: YesOrNo[1],
                });
            } else {
                this.setState({
                    RDR: YesOrNo[0],
                });
            }
        }

        await this.setState({
            DrValueMultiSelect: item,
            isEdit: true,
            isReset: false,
            isUpdate: this.state.patientId ? false : true,
        });
    };

    handleDr = async () => {
        if (this.state.drChecked) {
            this.setState((prevState) => ({
                drChecked: !prevState.drChecked,
                unableToGrade: -1,
                unableToGradeCheck: false,
                // drChecked: -1,
                isEdit: true,
                isReset: false,
                RDR: -1,
            }));
        } else {
            this.setState((prevState) => ({
                drChecked: !prevState.drChecked,
                unableToGradeCheck: false,
                unableToGrade: -1,
                // DrValue: -1,
                DrValueSingleSelect: -1,
                DrValueMultiSelect: -1,
                isEdit: true,
                isReset: false,
                RDR: -1,
            }));
        }
    };


    handleFileUpload = async (files, dilationType, diagnosisType) => {
        console.log("checking handleFileUpload-->",files,files.length,dilationType,diagnosisType);
        let file = {};
        for (var i = 0; i < files.length; i++) {
            file = {
                fileName: files[0].name,
                dilationType: dilationType,
                size: files[0].size,
                mime: files[0].type,
                path: files[0].base64,
                diagnosisType: diagnosisType.slice(-2),
                deviceId: isMobile || isTablet ? `${mobileVendor}` : "",
                deviceName: isMobile || isTablet ? `${mobileModel}` : "",
                browserName: browserName,
                platForm: osName,
                deviceType:files[0].deviceType === "FOP" ? "FOP" : "",
                date:files[0].date ? files[0].date : "",
                remidioReport:files[0].remidioReport ? files[0].remidioReport : "",

            };
        }
        console.log("handleFileUpload",file);
        this.setState({
            previewItem: file,
            ImgdilationType:dilationType,
            modal: !this.state.modal,
            isEdit: true,
            isReset: false,
            isDiscard: false,
            quadrant: -1,
            isUpdate: this.state.patientId ? false : true,
        });
    };

    handleFileUploadMulti = async (files, dilationType, diagnosisType) => {
        console.log("checking handleFileUploadmultiple-->",files,files.length,dilationType,diagnosisType);
        let file = {};
        // for (var i = 0; i < files.length; i++) {
            file = {
                fileName: files[0].name,
                dilationType: dilationType,
                size: files[0].size,
                mime: files[0].type,
                path: files[0].base64,
                diagnosisType: diagnosisType.slice(-2),
                deviceId: isMobile || isTablet ? `${mobileVendor}` : "",
                deviceName: isMobile || isTablet ? `${mobileModel}` : "",
                browserName: browserName,
                platForm: osName,
                deviceType:files[0].deviceType === "FOP" ? "FOP" : "",
                date:files[0].date ? files[0].date : "",
                remidioReport:files[0].remidioReport ? files[0].remidioReport : "",

            };
        // }
        console.log("handleFileUploadmultiple",file);
        return file;
        // this.setState({
        //     previewItem: file,
        //     modal: true,
        //     isEdit: true,
        //     isReset: false,
        //     isDiscard: false,
        //     quadrant: -1,
        //     isUpdate: this.state.patientId ? false : true,
        // });
    };

    // handleFetchImageInfo = (imageUrl) =>  {
    //     return fetch(imageUrl)
    //       .then(response => {
    //         if (!response.ok) {
    //           throw new Error("Network response was not ok");
    //         }
    //         return response.blob();
    //       })
    //       .then(blob => {
    //         const url = new URL(imageUrl);
    //         const fileName = url.pathname.split('/').pop();
    //         const imageSize = blob.size;
    //         const imageType = blob.type;
    //         const imginfo = [{"filename": fileName, "size": imageSize, "type": imageType,"path":url}];
    //         return imginfo;
    //       });
    //   }

      handleFetchImageInfo = (imageUrl, filename="image.jpg") =>  {
//         const cacheBuster = new Date().getTime();
//   imageUrl = `${imageUrl}?v=${cacheBuster}`;

        return fetch(imageUrl)
    .then(response => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.blob();
    })
    .then(blob => {
      const url = new URL(imageUrl);

      // Get the file name from the URL
      const fileName = url.pathname.split('/').pop();
      const imageSize = blob.size; // Size of the image in bytes
      const imageType = blob.type; // MIME type of the image
      const imageFile = new File([blob], filename, { type: blob.type },{ size: blob.size });
      // Convert the Blob to a Base64 URL
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve({
            file: imageFile,
            name: fileName+'.jpeg',
            type: imageType,
            size: imageSize,
            path: fileName+'.jpeg',
            base64: reader.result,
          });
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
      }
    handleRemidio = async (dilationType, diagnosisType) => {
    //    console.log("TYPE",type);
    // alertDangerConfirmMessage(`while updating, please enter the field `);
            // console.log("Remidio",this.state);
            if (!this.state.mocDocPhId && !this.state.visionCenterId) {
                alertDangerConfirmMessage(`mocDocPhId & Vision Center is not Found`);
              } else if (!this.state.mocDocPhId) {
                alertDangerConfirmMessage(`MocDoc Patient ID is not Found`);
              } else if (!this.state.visionCenterId) {
                alertDangerConfirmMessage(`Vision Center name is not Found`);
              } else {
                // if(this.state){
              const token = await localStorage.getItem("token");
            const params = {patientId:this.state.mocDocPhId,visionCenter:this.state.visionCenterId}
            // const params = {patientId:"1284",visionCenter:"Pappampatti"}
            
            await PatientService.getRemidioPatientInfo(params,token)
               .then(async (res) => {
                 if (res.status === 200) {
                   try {
                     res.text().then(async (res) => {
                      console.log(res)
                       let result = JSON.parse(res);
                       console.log(result);
                       if(result.error){
                        const errorMes = result.message;
                        alertDangerConfirmMessage(`${errorMes}`);
                        return false;
                       }
                         if (result.status) {
                             if (result.status.statusCode === "OK") {
                                 const fopImages = result.data.images.fopImages.STANDARD;
                                 const aiReport = result.data.aiReport;
                                 // console.log("IMAGES",result.data.images.fopImages.STANDARD)
                                 const imgArr = [];
                                 if (fopImages.length > 0) {
                                    this.setState({
                                        previewItemAll: [],
                                        ImgdilationType:dilationType
                                    });
                                 for (var i = 0; i < fopImages.length; i++) {
                                     // console.log("Image Info:", fopImages[i].laterality,fopImages[i].path);
                                     if (fopImages[i].laterality === "RIGHT" && applicationConstants.optoRE === this.state.diagnosisType) {
                                         let deviceType = fopImages[i].deviceType;
                                         this.handleFetchImageInfo(fopImages[i].path, fopImages[i].id).then(imginfo => {
                                             console.log("Image Info:", imginfo);

                                             let fileInfo = {
                                                 name: imginfo.name,
                                                 path: imginfo.base64,
                                                 type: imginfo.type,
                                                 size: Math.round(imginfo.size / 1000) + " kB",
                                                 base64: imginfo.base64,
                                                 file: imginfo.file,
                                                 deviceType: deviceType,
                                                 date: aiReport != null || aiReport != undefined ? aiReport.generatedDate : "",
                                                 remidioReport: aiReport != null || aiReport != undefined ? aiReport.path : "",
                                             };

                                             imgArr.push(fileInfo);
                                             // handleFile
                                             console.log("fileinfo", fileInfo);
                                             this.handleFile(imgArr, dilationType, diagnosisType);
                                             this.setState({
                                                previewItemAll: [...this.state.previewItemAll,imgArr]
                                            });
                                         })
                                             .catch(error => {
                                                //  console.error("Error fetching image:", error);
                                                 alertDangerConfirmMessage(`Error fetching image - Right: ${error}`);
                                             });
                                     }
                                     if (fopImages[i].laterality === "LEFT" && applicationConstants.optoLE === this.state.diagnosisType) {
                                        let deviceType = fopImages[i].deviceType;
                                        this.handleFetchImageInfo(fopImages[i].path, fopImages[i].id).then(imginfo => {
                                            console.log("Image Info:", imginfo);

                                            let fileInfo = {
                                                name: imginfo.name,
                                                path: imginfo.base64,
                                                type: imginfo.type,
                                                size: Math.round(imginfo.size / 1000) + " kB",
                                                base64: imginfo.base64,
                                                file: imginfo.file,
                                                deviceType: deviceType,
                                                date: aiReport != null || aiReport != undefined ? aiReport.generatedDate : "",
                                                remidioReport: aiReport != null || aiReport != undefined ? aiReport.path : "",
                                            };

                                            imgArr.push(fileInfo);
                                            // handleFile
                                            console.log("fileinfo", fileInfo);
                                            this.handleFile(imgArr, dilationType, diagnosisType);
                                            this.setState({
                                                previewItemAll: [...this.state.previewItemAll,imgArr]
                                            });
                                        })
                                            .catch(error => {
                                                // console.error("Error fetching image:", error);
                                                alertDangerConfirmMessage(`Error fetching image: ${error}`);
                                            });
                                    }
                                 }

                                } else {
                                    alertDangerConfirmMessage(`Data not Found`);
                                }
                             }
                         }
                    
                     });
                   } catch (err) {
                     console.log(err);
                   }
                 }
               })
               .catch((err) => {
                 console.log(err);
               });
              }
            
          
    }

    toggle() {
        this.setState({
        modal: !this.state.modal,
        });
    }

    handlePreviewImage = async (item) => {
        console.log("handlePreviewImage", item);
        if (item.path === undefined) {
            const data = await this.convertURLToBase64(item.signedUrl);
            if (data !== "") {
                let obj = { path: data };
                this.isDuplicateItem(obj, true);
            }
        } else {
            this.isDuplicateItem(item, true);
        }
        item.diagnosisType = await this.state.diagnosisType.slice(-2);
        await imageQuadrant.find((data) => {
            if (data.label === item.quadrant) {
                this.setState({
                    quadrant: data.value,
                });
            }
        });
        await this.setState({
            modal: !this.state.modal,
            previewItem: item,
            
            isDiscard: true,
        });
    };

    handlePreviewData = async (item) => {
        await this.refreshMediaSelect(item);
        //console.log("________handlePreviewData", this.state);
        let preDilationMediaArray = [];
        let postDilationMediaArray = [];
        let deleteS3ItemArray = [];
        delete item.diagnosisType;
        if (item.objKey != null) {
            deleteS3ItemArray.push(item);
            this.setState({
                deleteS3Obj: deleteS3ItemArray,
                isEdit: true,
            });
        }
        if (item.dilationType === "PRE") {
            preDilationMediaArray.push(item);
            let preArray = [...this.state.preDilationMedia, ...preDilationMediaArray];
            await this.setState({
                preDilationMedia: preArray,
            });
        }
        if (item.dilationType === "POST") {
            postDilationMediaArray.push(item);
            let postArray = [
                ...this.state.postDilationMedia,
                ...postDilationMediaArray,
            ];
            await this.setState({
                postDilationMedia: postArray,
            });
        }
        return this.state;
    };

    refreshMediaSelect = async (item) => {
        if (item.dilationType === "PRE") {
            if (this.state.mediaPreSelect.length > 0) {
                let objIndex = this.state.mediaPreSelect.findIndex(
                (obj) => obj.base64 === item.path
                );
                this.state.mediaPreSelect.splice(objIndex, 1);
            }
        } else {
            if (this.state.mediaPostSelect.length > 0) {
                let objIndex = this.state.mediaPostSelect.findIndex(
                (obj) => obj.base64 === item.path
                );
                this.state.mediaPostSelect.splice(objIndex, 1);
            }
        }
        await this.setState({
            mediaPreSelect: this.state.mediaPreSelect,
            mediaPostSelect: this.state.mediaPostSelect,
            isEdit: true,
        });
        //  console.log("_______refreshData", this.state);
    };

    discardSelectMediaItem = async (item) => {
        if (item.dilationType === "PRE") {
        if (this.state.mediaPreSelect.length > 0) {
            let objIndex = this.state.mediaPreSelect.findIndex(
            (obj) => obj.name === item.fileName
            );
            this.state.mediaPreSelect.splice(objIndex, 1);
        }
        } else {
        if (this.state.mediaPostSelect.length > 0) {
            let objIndex = this.state.mediaPostSelect.findIndex(
            (obj) => obj.name === item.fileName
            );
            this.state.mediaPostSelect.splice(objIndex, 1);
        }
        }
        await this.setState({
            mediaPreSelect: this.state.mediaPreSelect,
            mediaPostSelect: this.state.mediaPostSelect,
        });
    };

    handleSelectMedia = (item, dilationType, diagnosisType) => {
        let previewItem = [];
        previewItem.push(item);
        // console.log(previewItem, diagnosisType, dilationType);
        this.handleFileUpload(previewItem, dilationType, diagnosisType);
    };

    loadVideo = async (file) => await new Promise((resolve, reject) => {
        try {
            let video = document.createElement("video");
            video.preload = "metadata";

            video.onloadedmetadata = function () {
            resolve(this);
            };

            video.onerror = function () {
            reject("Invalid video. Please select a video file.");
            };

            video.src = window.URL.createObjectURL(file);
        } catch (e) {
            reject(e);
        }
    });

    handleFile = async (file, dilationType, diagnosisType) => {
        console.log("checking handleFile-->",file,dilationType,diagnosisType);
        this.setState({
            isLoading: true,
        });
        await file.map(async (item) => {
            if (item.type === "video/mp4" || item.type === "video/webm") {
                const video = await this.loadVideo(item.file);
                item.duration = video.duration;
                console.log(video.duration);
            }
        });
        const videoItemCount = await file.filter(
            (item) => item.type === "video/mp4" || item.type === "video/webm"
        );
        const timer = videoItemCount.length * 1000;
        await setTimeout(async () => {
            await this.handleFileItem(file, dilationType, diagnosisType);
            await this.setState({
                isLoading: false,
            });
        }, timer);
    };

    handleFileItem = async (file, dilationType, diagnosisType) => {
        console.log("checking handleFileItem-->",file,dilationType,diagnosisType);
        // this.setState({
        //     previewItemAll:[]
        // });
        const uploadItem = await file.filter(
        (item) =>
            (parseInt(item.size.split(" ")[0]) <= 10000 &&
            (item.type === "image/jpeg" || item.type === "image/png")) ||
            (item.duration <= 90 &&
            (item.type === "video/mp4" || item.type === "video/webm"))
        );

        const inValidUploadItem = await file.filter(
        (item) =>
            (parseInt(item.size.split(" ")[0]) > 10000 &&
            (item.type === "image/jpeg" || item.type === "image/png")) ||
            (item.duration > 90 &&
            (item.type === "video/mp4" || item.type === "video/webm"))
        );
                console.log("handleFileItem",uploadItem);
        if (dilationType === "PRE") {
        this.setState({
            mediaPreSelect: uploadItem,
            // previewItemAll:uploadItem
        });
        // if (uploadItem.length === 1) {
        //     this.handleFileUpload(uploadItem, dilationType, diagnosisType);
        // }
        if (inValidUploadItem.length > 0) {
            let message = "";
            await inValidUploadItem.map((item) => {
            message = item.name + " " + message;
            });
            alertDangerConfirmMessage(`${message} are not valid`);
        }
        } else {
        this.setState({
            mediaPostSelect: uploadItem,
        });
        if (inValidUploadItem.length > 0) {
            let message = "";
            await inValidUploadItem.map((item) => {
            message = item.name + " " + message;
            });
            alertDangerConfirmMessage(`${message} are not valid`);
        }
        // if (uploadItem.length === 1) {
        //     this.handleFileUpload(uploadItem, dilationType, diagnosisType);
        // }
        }
        console.log("checking PreviewState-->",this.state.previewItemAll);
    };

    refreshList = async (item) => {
        await this.discardDuplicateItem(item);
        if (item.dilationType === "PRE") {
        if (this.state.preDilationMedia.length > 0) {
            let objIndex = this.state.preDilationMedia.findIndex(
            (obj) => obj.fileName === item.fileName
            );
            this.state.preDilationMedia.splice(objIndex, 1);
        }
        } else {
        if (this.state.postDilationMedia.length > 0) {
            let objIndex = this.state.postDilationMedia.findIndex(
            (obj) => obj.fileName === item.fileName
            );
            this.state.postDilationMedia.splice(objIndex, 1);
        }
        }
        await this.setState({
            preDilationMedia: this.state.preDilationMedia,
            postDilationMedia: this.state.postDilationMedia,
            isEdit: true,
        });
    };

    discardItem = async (item, message) => {
        if (item.objKey) {
            // console.log("discardItem" + JSON.stringify(item.objKey));
            await deleteItemArray.push({
                objKey: item.objKey,
                message: message,
            });
            await this.setState({
                discardItems: deleteItemArray,
            });
        }
    };

    updateItem = async (item, newItem) => {
        let newPreDilationArray = [];
        let newPostDilationArray = [];
        if (item.dilationType === "PRE") {
            if (this.state.preDilationMedia.length > 0) {
                let objIndex = this.state.preDilationMedia.findIndex(
                (obj) => obj.path === item.path
                );
                this.state.preDilationMedia.splice(objIndex, 1);
                newPreDilationArray.push(newItem);
            }
        } else {
            if (this.state.postDilationMedia.length > 0) {
                let objIndex = this.state.postDilationMedia.findIndex(
                (obj) => obj.path === item.path
                );
                this.state.postDilationMedia.splice(objIndex, 1);
                newPostDilationArray.push(newItem);
            }
        }
        let preMedia = [...this.state.preDilationMedia, ...newPreDilationArray];
        let postMedia = [...this.state.postDilationMedia, ...newPostDilationArray];
        await this.setState({
            preDilationMedia: preMedia,
            postDilationMedia: postMedia,
            isEdit: true,
        });
    };

    extractDateFromUrl = (url) =>  {
        const urlParts = url.split('/');
        const filename = urlParts[urlParts.length - 1];
        const datePart = filename.match(/(\d{8})/);
    
        if (datePart) {
          const dateString = datePart[1];
          return new Date(
            parseInt(dateString.substr(0, 4), 10),
            parseInt(dateString.substr(4, 2) - 1, 10),
            parseInt(dateString.substr(6, 2), 10)
          );
        }
        
        return null;
      }

    getMaxDateUrl = (urls) => {
        // Extract the dates from the URLs
        
      
        let maxDateUrl = null;
        let maxDate = null;
      
        for (const url of urls) {
          const date = this.extractDateFromUrl(url);
      
          if (date) {
            if (!maxDate || date > maxDate) {
              maxDate = date;
              maxDateUrl = url;
            }
          }
        }
      
        return maxDateUrl;
      }
      

    handleSubmit = async () => {
        clearInterval(this.interval);
        const token = await localStorage.getItem("token");
        console.log(this.state.postDilationMedia);
        if (!this.state.isUpdate) {
            if (
                (!this.state.unableToGradeCheck && !this.state.drChecked) ||
                (this.state.unableToGradeCheck &&
                this.state.unableToGrade.value !== undefined) ||
                (this.state.DrValueSingleSelect !== -1 && this.state.drChecked) ||
                (this.state.DrValueMultiSelect !== -1 && this.state.drChecked)
            ) {
                this.setState({
                isLoading: true,
                });
                let DRArrayList = [];
                let DRLists = [];
                let DRArraySingle = [];
                let DRArrayMulti = [];

                if (this.state.drChecked && this.state.DrValueSingleSelect !== -1) {
                    DRArraySingle.push(this.state.DrValueSingleSelect.value);
                }
                if (this.state.DrValueMultiSelect !== null) {
                    if (this.state.drChecked && this.state.DrValueMultiSelect !== -1) {
                        DRArrayMulti.push(this.state.DrValueMultiSelect.value);
                    }
                }

                DRLists = [...DRArraySingle, ...DRArrayMulti];
                if (DRLists.length > 0) {
                    DRLists.map((item) => {
                        if (item != undefined) {
                            DRArrayList.push(item);
                        }
                    });
                }

                // -----------------------------------------------

                //Delete Post And Pre Dialtion image from s3 //
                if (this.state.discardItems.length > 0) {
                    for (let i = 0; i < this.state.discardItems.length; i++) {
                        const objKey = this.state.discardItems[i].objKey;
                        await deleteItem(objKey).then(async (result) => {
                            if (result.status === 200) {
                                if (this.state.discardItems[i].message === 0) {
                                    console.log(`Deleting file`);
                                }
                                if (this.state.discardItems[i].message === 1) {
                                    console.log(`Updating file`);
                                }
                            }
                        }).catch((err) => {
                            console.log(err);
                        });
                    }
                }

                //Updating Post Dialtion image to s3 and modified local object //
                for (var post = 0; post < this.state.postDilationMedia.length; post++) {
                    if (this.state.postDilationMedia[post].objKey === undefined) {
                        let file = {
                            uri: `${this.state.postDilationMedia[post].path}`,
                            name: `${this.state.patientId}/RE/POST/${
                                this.state.postDilationMedia[post].fileName
                            }.${this.state.postDilationMedia[post].mime.split("/").pop()}`,
                            type: this.state.postDilationMedia[post].mime,
                            size: this.state.postDilationMedia[post].size,
                        };

                        await s3UploadFile(file).then(async (uploadResult) => {
                            try {
                                if (uploadResult.status === 200) {
                                    const result = uploadResult;
                                    //  console.log("post result", result);
                                    this.state.postDilationMedia[ post ].signedUrl = `${AwsConfig.url}${result.file.name}`;
                                    this.state.postDilationMedia[post].objKey =
                                    result.file.name;
                                    delete this.state.postDilationMedia[post].path;
                                    //console.log("post", this.state.postDilationMedia);
                                } else {
                                    // console.log("uploadResult", JSON.stringify(uploadResult));
                                }
                            } catch (err) {
                                console.log(err);
                            }
                        }).catch((err) => {
                            console.log(err);
                        });
                    } else {
                        delete this.state.postDilationMedia[post].path;
                    }
                }

                //Updating Pre Dialtion image to s3 and modified local object //
                for (var pre = 0; pre < this.state.preDilationMedia.length; pre++) {
                    if (this.state.preDilationMedia[pre].objKey === undefined) {
                        let file = {
                            uri: `${this.state.preDilationMedia[pre].path}`,
                            name: `${this.state.patientId}/RE/PRE/${
                                this.state.preDilationMedia[pre].fileName
                            }.${this.state.preDilationMedia[pre].mime.split("/").pop()}`,
                            type: this.state.preDilationMedia[pre].mime,
                            size: this.state.preDilationMedia[pre].size,
                        };
                        await s3UploadFile(file).then(async (uploadResult) => {
                            try {
                                if (uploadResult.status === 200) {
                                    const result = uploadResult;
                                    //console.log("pre result", this.state.preDilationMedia);
                                    this.state.preDilationMedia[
                                    pre
                                    ].signedUrl = `${AwsConfig.url}${result.file.name}`;
                                    this.state.preDilationMedia[pre].objKey = result.file.name;
                                    delete this.state.preDilationMedia[pre].path;
                                    //console.log("pre", this.state.preDilationMedia);
                                } else {
                                    //console.log("uploadResult", JSON.stringify(uploadResult));
                                }
                            } catch (err) {
                                console.log(err);
                            }
                        }).catch((err) => {
                            console.log(err);
                        });
                    } else {
                        delete this.state.preDilationMedia[pre].path;
                    }
                }
                const loginId = await localStorage.getItem("loginId");
                const data = {
                    patientId: this.state.patientId,
                    abnormalDisc: this.state.abnormalDisc ? 1 : 0,
                    DR: DRArrayList.length > 0 ? `[${DRArrayList}]` : -1,
                    unableToGrade: this.state.unableToGradeCheck ? this.state.unableToGrade.value : -1,
                    other: this.state.comments,
                    otherdiagnosis:this.state.otherdiagnosis!=""?this.state.otherdiagnosis:"",
                    RDR: this.state.RDR !== -1 ? this.state.RDR.value : -1,
                    preDilationImaging: this.state.preDilationMedia.length > 0 ? this.state.preDilationMedia : "[]",
                    postDilationImaging: this.state.postDilationMedia.length > 0 ? this.state.postDilationMedia : "[]",
                    diagnosisType: this.state.diagnosisType,
                    Duration: this.state.duration + this.state.timer,
                    loginId: loginId
                };

                await PatientService.addOrUpdateDiagnosis(data, token).then(async (res) => {
                    if (res.status === 200) {
                        try {
                            res.text().then(async (res) => {
                                let result = JSON.parse(res);
                                if (result.code === 0) {
                                    this.setState({
                                        isLoading: false,
                                    });
                                    //  console.log(JSON.stringify(result));
                                    this.props.history.push("/Dashboard");
                                } else {
                                    this.setState({
                                        isLoading: false,
                                    });
                                    alertDangerConfirmMessage(`${result.message}`);
                                }
                            });
                        } catch (err) {
                            this.setState({
                                isLoading: false,
                            });
                            console.log(err);
                        }
                    }
                }).catch((err) => {
                    this.setState({
                        isLoading: false,
                    });
                    console.log(err);
                });
            } else {
                alertDangerConfirmMessage(
                `please select ${this.state.drChecked ? "Grade" : "Grade"}`
                );
            }
        } else {
            alertDangerConfirmMessage(`while updating, please enter the field `);
        }
    };

    handleBackArrow = () => {
        if (this.state.campStatus !== "0") {
            if (this.state.isEdit) {
                this.setState({
                    isConfirmation: !this.state.isConfirmation,
                    isReset: false,
                });
            } else {
                this.props.history.push("/Dashboard");
            }
        } else {
            this.props.history.push("/Dashboard");
        }
    };

    handleCancelBtn = () => {
        this.setState({
            isConfirmation: !this.state.isConfirmation,
            isReset: false,
        });
    };

    handleResetBtn = () => {
        this.setState({
            isConfirmation: !this.state.isConfirmation,
            isReset: true,
        });
    };

    handleConfirmBtn = async () => {
        if (this.state.isReset && !this.state.isUpdate) {
            if (this.state.deleteS3Obj.length > 0) {
                for (var i = 0; i < this.state.deleteS3Obj.length; i++) {
                    //console.log(this.state.deleteS3Obj[i].objKey);
                    await deleteItem(this.state.deleteS3Obj[i].objKey).then(async (result) => {
                        if (result.status == 200) {
                            console.log("deleted items from s3Bucket", result.data);
                        }
                    }).catch((err) => {
                        console.log(err);
                        this.props.history.push("/Dashboard");
                    });
                }
            }

            this.setState({
                abnormalDisc: false,
                drChecked: false,
                // DrValue: -1,
                DrValueSingleSelect: -1,
                DrValueMultiSelect: -1,
                unableToGradeCheck: false,
                RDR: -1,
                preDilationMedia: (applicationConstants.optoRE === this.state.diagnosisType) || (applicationConstants.optoLE === this.state.diagnosisType) ? [] : this.state.preDilationMedia,
                postDilationMedia: (applicationConstants.optoRE === this.state.diagnosisType) || (applicationConstants.optoLE === this.state.diagnosisType) ? [] : this.state.postDilationMedia,
                unableToGrade: -1,
                comments: "",
                modal: false,
                previewItem: "",
                quadrant: -1,
                mediaPreSelect: [],
                mediaPostSelect: [],
                discardItems: [],
                deleteS3Obj: [],
                isConfirmation: !this.state.isConfirmation,
                isReset: false,
                isUpdate: this.state.patientId ? true : false,
            });
        } else {
            if (this.state.deleteS3Obj.length > 0) {
                for (var i = 0; i < this.state.deleteS3Obj.length; i++) {
                 
                    await deleteItem(this.state.deleteS3Obj[i].objKey).then(async (result) => {
                        if (result.status == 200) {
                            console.log("deleted items from s3Bucket", result.data);
                        }
                    }).catch((err) => {
                        console.log(err);
                        this.props.history.push("/Dashboard");
                    });
                }
            }
            this.props.history.push("/Dashboard");
        }
    };

    handleQuickHelp = () => {
        this.setState({
            isQuickHelp: !this.state.isQuickHelp,
        });
    };

    isDuplicateItem = async (item, isUpdate) => {
        if (item.path !== undefined) {
            if (isUpdate) {
                if (this.state.isDuplicateImg.length > 0) {
                    this.state.isDuplicateImg.map((a) => {
                        if (a.path !== undefined) {
                        if (a.path.split(",")[1] === item.path.split(",")[1]) {
                            a.isUpdate = isUpdate;
                        }
                    }
                    });
                }
            } else {
                let list = [];
                let isAdd = false;
                if (this.state.isDuplicateImg.length > 0) {
                    this.state.isDuplicateImg.map((a) => {
                        if (a.path !== undefined) {
                        if (a.path.split(",")[1] === item.path.split(",")[1]) {
                            a.isUpdate = isUpdate;
                            isAdd = true;
                        }
                    }
                    });
                    if (!isAdd) {
                        list.push({ path: item.path, isUpdate: isUpdate });
                    }
                } else {
                    list.push({ path: item.path, isUpdate: isUpdate });
                }
                this.setState({
                    isDuplicateImg: [...this.state.isDuplicateImg, ...list],
                });
            }
        } else {
            const data = await this.convertURLToBase64(item.signedUrl);
            let list = [];
            if (data !== "") {
                let obj = { path: data };
                if (this.state.isDuplicateImg.length > 0) {
                    this.state.isDuplicateImg.map((a) => {

                        if (a.path!=undefined && (a.path.split(",")[1] === obj.path.split(",")[1])) {
                            a.isUpdate = isUpdate;
                        }
                    });
                } else {
                    list.push({ path: obj.path, isUpdate: isUpdate });
                }
                this.setState({
                    isDuplicateImg: [...this.state.isDuplicateImg, ...list],
                });
            }
        }
    };

    discardDuplicateItem = async (item) => {
        if (item.path !== undefined) {
            if (this.state.isDuplicateImg.length > 0) {
                let objIndex = this.state.isDuplicateImg.findIndex(
                (obj) => obj.path === item.path
                );
                this.state.isDuplicateImg.splice(objIndex, 1);
            }
        } else {
            const data = await this.convertURLToBase64(item.signedUrl);
            if (data !== "") {
                if (this.state.isDuplicateImg.length > 0) {
                    let objIndex = this.state.isDuplicateImg.findIndex(
                        (obj) => obj.path === data
                    );
                    this.state.isDuplicateImg.splice(objIndex, 1);
                }
            }
        }
    };

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        let disabledBtn = false;
        if (userRoleData[1].label === this.state.roleId) {
            disabledBtn = // userRoleData[0].roleId === this.state.roleId &&
            this.state.diagnosisType === applicationConstants.optoRE ||
            this.state.diagnosisType === applicationConstants.optoLE
            ? false
            : true;
        }
        if (userRoleData[2].label === this.state.roleId) {
            disabledBtn = // userRoleData[0].roleId === this.state.roleId &&
            this.state.diagnosisType === applicationConstants.optoRE ||
            this.state.diagnosisType === applicationConstants.optoLE ||
            this.state.diagnosisType === applicationConstants.AtCampRE ||
            this.state.diagnosisType === applicationConstants.AtCampLE
            ? false
            : true;
        }

        return (
            <Strip
                id="tst-Diagonsis"
                className="strip strip-no-padding"
                containerType="container-fluid"
            >
            <Row className="no-margin mb-4">
            <Col xs={12} sm={12} md={10} lg={12} xl={12} className="m-auto">
                <BackArrow
                title={this.state.title}
                handleBackArrow={this.handleBackArrow}
                isQuick={true}
                handleQuickHelp={this.handleQuickHelp}
                />
                <Row className="bg-light-white bg-white p-4">
                {this.state.patientId ? (
                    <Col
                    xs={12}
                    sm={12}
                    md={8}
                    lg={10}
                    xl={10}
                    className="p-0"
                    >
                    <p className="p-large internal-page__title text-capitalize align-left title-suvaGrey pat-title">
                        {this.state.patientName} - {this.state.patientId}
                    </p>
                    </Col>
                ) : null}
                <Col
                    xs={12}
                    sm={12}
                    md={4}
                    lg={2}
                    xl={2}
                    className="px-0 info-txt"
                >
                    <p className="p-small internal-page__title  mb-0 text-capitalize align-left title-suvaGrey">
                    max size for file Upload
                    </p>
                    <p className="p-small internal-page__title  mb-0 text-capitalize align-left title-suvaGrey">
                    image : 10 MB
                    </p>
                    <p className="p-small internal-page__title  mb-0 text-capitalize align-left title-suvaGrey">
                    video : 90 sec
                    </p>
                </Col>
                <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="bg-white no-mobile-plr p-0"
                >
                    
                    <Form className="p-0 form-fields field-col-4">
                    <Col xs={12} sm={12} md={6} lg={4} xl={3} className="p-0">
                        <FormGroup>
                        <Dilation
                            diagnosisType={this.state.diagnosisType}
                            dilationMedia={this.state.preDilationMedia}
                            title={"Pre-dilated fundus image"}
                            dilationType={"PRE"}
                            remidioType={this.state.imageType}
                            handleRemidio={this.handleRemidio}
                            handleFileUpload={(data, dilationType, diagnosisType) =>
                            this.handleFile(data, dilationType, diagnosisType)
                            }
                            handlePreviewImage={(item) =>
                            this.handlePreviewImage(item)
                            }
                        />
                        </FormGroup>
                        {this.state.mediaPreSelect.length > 0 ? (
                        <FormGroup>
                            <CapturedImage
                            selectMedia={this.state.mediaPreSelect}
                            diagnosisType={this.state.diagnosisType}
                            dilationType={"PRE"}
                            title={`Pre-dilated fundus image`}
                            remidioType={this.state.imageType}
                            handleFileUpload={(data, dilationType, diagnosisType) =>
                                this.handleFileUpload(
                                data,
                                dilationType,
                                diagnosisType
                                )
                            }
                            />
                        </FormGroup>
                        ) : null}             
                        {/* {this.state.preDilationMedia.length > 0 && this.state.preDilationMedia[0].remidioReport !== "" ? (
                            
                            <Row className="mb-3 no-margin  no-mobile-plr">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="p-0">
                            <a href={`${this.state.preDilationMedia[0].remidioReport}`}
                            className={`d-flex justify-content-center`}
                             download={`${this.state.preDilationMedia[0].date}.pdf`} 
                             target={`_blank`} >
                                 <p
                                className={`mb-0 common-status-btn report-color-btn`}
                                >
                                Remidio Report
                                </p>
                                </a>

                            
                            </Col>
                        </Row>    ) : null}      */}
                        <FormGroup>
                        <Dilation
                            diagnosisType={this.state.diagnosisType}
                            dilationMedia={this.state.postDilationMedia}
                            title={"Post-dilated fundus image"}
                            dilationType={"POST"}
                            remidioType={this.state.imageType}
                            handleRemidio={this.handleRemidio}
                            handleFileUpload={(data, dilationType, diagnosisType) =>
                            this.handleFile(data, dilationType, diagnosisType)
                            }
                            handlePreviewImage={(item) =>
                            this.handlePreviewImage(item)
                            }
                        />
                        </FormGroup>
                        {this.state.mediaPostSelect.length > 0 ? (
                        <FormGroup>
                            <CapturedImage
                            selectMedia={this.state.mediaPostSelect}
                            diagnosisType={this.state.diagnosisType}
                            dilationType={"POST"}
                            remidioType={this.state.imageType}
                            title={`Post-dilated fundus image`}
                            handleFileUpload={(data, dilationType, diagnosisType) =>
                                this.handleFileUpload(
                                data,
                                dilationType,
                                diagnosisType
                                )
                            }
                            />
                        </FormGroup>
                        
                        ) : null}   
                        
                        
                        {this.state.diagnosisType === "optoRE"? (
                            
                            <Row className="mb-3 no-margin no-mobile-plr">
                            {(() => {
                              const postDilationMediaWithRemidio = this.state.postDilationMedia
                                .filter(data => data.remidioReport); // Filter objects with non-empty remidioReport
                          
                              const lastPostDilationRemidio = postDilationMediaWithRemidio.length > 0
                                ? postDilationMediaWithRemidio.slice(-1)[0] // Get the last object from postDilationMedia
                                : null;
                          
                              const lastPreDilationRemidio = this.state.preDilationMedia
                                .filter(data => data.remidioReport)
                                .slice(-1)[0]; // Get the last object from preDilationMedia
                          
                              const selectedRemidio = lastPostDilationRemidio || lastPreDilationRemidio;
                          
                              if (selectedRemidio) {
                                return (
                                  <Col xs={12} sm={12} md={12} lg={12} xl={12} className="p-0">
                                    <a
                                      href={selectedRemidio.remidioReport}
                                      className={`d-flex justify-content-center`}
                                      download={`${selectedRemidio.date}.pdf`}
                                      target={`_blank`}
                                    >
                                      <p className={`mb-0 common-status-btn report-color-btn`}>
                                        View Remidio RE Report
                                      </p>
                                    </a>
                                  </Col>
                                );
                              } else {
                                return null; // You can also render a default component or message here if needed
                              }
                            })()}
                          </Row>    ) : null}    
                       
                      
                        {this.state.diagnosisType === "optoLE" ? (
                            
                            <Row className="mb-3 no-margin no-mobile-plr">
                            {(() => {
                              const postDilationMediaWithRemidio = this.state.postDilationMedia
                                .filter(data => data.remidioReport); // Filter objects with non-empty remidioReport
                          
                              const lastPostDilationRemidio = postDilationMediaWithRemidio.length > 0
                                ? postDilationMediaWithRemidio.slice(-1)[0] // Get the last object from postDilationMedia
                                : null;
                          
                              const lastPreDilationRemidio = this.state.preDilationMedia
                                .filter(data => data.remidioReport)
                                .slice(-1)[0]; // Get the last object from preDilationMedia
                          
                              const selectedRemidio = lastPostDilationRemidio || lastPreDilationRemidio;
                          
                              if (selectedRemidio) {
                                return (
                                  <Col xs={12} sm={12} md={12} lg={12} xl={12} className="p-0">
                                    <a
                                      href={selectedRemidio.remidioReport}
                                      className={`d-flex justify-content-center`}
                                      download={`${selectedRemidio.date}.pdf`}
                                      target={`_blank`}
                                    >
                                      <p className={`mb-0 common-status-btn report-color-btn`}>
                                        View Remidio LE Report
                                      </p>
                                    </a>
                                  </Col>
                                );
                              } else {
                                return null; // You can also render a default component or message here if needed
                              }
                            })()}
                          </Row>  ) : null}       
                    </Col>

                    <Col xs={12} sm={12} md={6} lg={4} xl={4} className="p-0 re-check">
                        
                        <Row
                        className={`no-margin cursor-pointer wid-100 ${
                            disabledBtn ? "pointer-events-none" : ""
                        }`}
                        onClick={this.handleAbnormalDisc}
                        >
                        {this.state.abnormalDisc ? (
                            <Col className="p-0 check-box">
                            <FontAwesomeIcon
                                icon={faCheckSquare}
                                color={`#72d389`}
                                size={"2x"}
                            />
                            <font className="common-label pl-12px">
                            No DR
                            </font>
                            </Col>
                        ) : (
                            <Col className="d-flex align-items-center check-box p-0">
                            <FontAwesomeIcon
                                icon={faSquare}
                                color={`#72d389`}
                                // border={'#72d389'}
                                className="sqaure-unchecked"
                                size={"2x"}
                            />{" "}
                            <font className="common-label pl-12px">
                            No DR
                            </font>
                            </Col>
                        )}
                        </Row>
                        <Row
                        className={`no-margin mt-10px mb-10px wid-100 cursor-pointer ${
                            disabledBtn ? "pointer-events-none" : ""
                        }`}
                        onClick={this.handleDr}
                        >
                        {this.state.drChecked ? (
                            <Col className="d-flex align-items-center check-box p-0">
                            <FontAwesomeIcon
                                icon={faCheckSquare}
                                color={`#72d389`}
                                size={"2x"}
                            />
                            <font className="common-label pl-12px">DR</font>
                            </Col>
                        ) : (
                            <Col className="d-flex align-items-center check-box p-0">
                            <FontAwesomeIcon
                                icon={faSquare}
                                color={`#72d389`}
                                // border={'#72d389'}
                                className="sqaure-unchecked"
                                size={"2x"}
                            />{" "}
                            <font className="common-label pl-12px">DR</font>
                            </Col>
                        )}
                        </Row>

                        {this.state.drChecked ? (
                        <FormGroup>
                            <Row>
                            <Col  xs={8} sm={8} md={8} lg={8} xl={8} >
                                <Select
                                value={this.state.DrValueSingleSelect}
                                onChange={this.handleDrvalue}
                                options={DRDataOne}
                                classNamePrefix="common-select-drop-down"
                                placeholder="Select Reason"
                                isDisabled={
                                    userRoleData[1].label === this.state.roleId
                                    ? disabledBtn
                                    : userRoleData[2].label === this.state.roleId
                                    ? disabledBtn
                                    : userRoleData[3].label === this.state.roleId
                                    ? disabledBtn
                                    : false
                                }
                                />
                            </Col>
                            <Col  xs={8} sm={8} md={8} lg={8} xl={8}>
                            <Label className="common-label">Macular edema</Label>
                                <Select
                                value={this.state.DrValueMultiSelect}
                                onChange={this.handleDrMultivalue}
                                options={DRDataTwo}
                                classNamePrefix="common-select-drop-down"
                                placeholder="Select Reason"
                                isDisabled={
                                    userRoleData[1].label === this.state.roleId
                                    ? disabledBtn
                                    : userRoleData[2].label === this.state.roleId
                                    ? disabledBtn
                                    : userRoleData[3].label === this.state.roleId
                                    ? disabledBtn
                                    : false
                                }
                                />
                            </Col>
                            </Row>
                        </FormGroup>
                        ) : null}
                    
                    
                        <Row
                        className={`no-margin mt-10px mb-10px cursor-pointer wid-100 ${
                            disabledBtn ? "pointer-events-none" : ""
                        }`}
                        onClick={this.handleGrade}
                        >
                        {this.state.unableToGradeCheck ? (
                            <Col className="d-flex align-items-center check-box p-0">
                            <FontAwesomeIcon
                                icon={faCheckSquare}
                                color={`#72d389`}
                                size={"2x"}
                            />
                            <font className="common-label pl-12px">
                                Unable to Grade
                            </font>
                            </Col>
                        ) : (
                            <Col className="d-flex align-items-center check-box p-0">
                            <FontAwesomeIcon
                                icon={faSquare}
                                color={`#72d389`}
                                // border={'#72d389'}
                                className="sqaure-unchecked"
                                size={"2x"}
                            />{" "}
                            <font className="common-label pl-12px">
                                Unable to Grade
                            </font>
                            </Col>
                        )}
                        </Row>
                        {this.state.unableToGradeCheck ? (
                        <FormGroup>
                            <Select
                            value={this.state.unableToGrade}
                            onChange={this.handleUnableToGrade}
                            options={unableToGradeData}
                            classNamePrefix="common-select-drop-down"
                            placeholder="Select Reason"
                            isSearchable={false}
                            isDisabled={
                                userRoleData[1].label === this.state.roleId
                                ? disabledBtn
                                : userRoleData[2].label === this.state.roleId
                                ? disabledBtn
                                : userRoleData[3].label === this.state.roleId
                                ? disabledBtn
                                : false
                            }
                            />
                        </FormGroup>
                        ) : null}
                
                    </Col>
                    
                    <Col xs={12} sm={12} md={12} lg={4} xl={4} className="p-0">
                        <FormGroup>
                        <Label className="common-label">Comments</Label>
                        <Input
                            type="textarea"
                            placeholder="comments"
                            onChange={this.handleChange}
                            name="comments"
                            className="form-control"
                            value={this.state.comments}
                            rows="4"
                            readOnly={
                            userRoleData[1].label === this.state.roleId
                                ? disabledBtn
                                : userRoleData[2].label === this.state.roleId
                                ? disabledBtn
                                : userRoleData[3].label === this.state.roleId
                                ? disabledBtn
                                : false
                            }
                        />
                        </FormGroup>
                        <FormGroup>
                        <Label className="common-label">Other Diagnosis</Label>
                        <Input
                            type="textarea"
                            placeholder="other diagnosis"
                            onChange={this.handleChange}
                            name="otherdiagnosis"
                            className="form-control"
                            value={this.state.otherdiagnosis}
                            rows="4"
                            readOnly={
                            userRoleData[1].label === this.state.roleId
                                ? disabledBtn
                                : userRoleData[2].label === this.state.roleId
                                ? disabledBtn
                                : userRoleData[3].label === this.state.roleId
                                ? disabledBtn
                                : false
                            }
                        />
                        </FormGroup>
                        <FormGroup>
                        <Label className="common-label">RDR</Label>
                        <Select
                            value={this.state.RDR}
                            onChange={this.handleRDR}
                            options={YesOrNo}
                            classNamePrefix="common-select-drop-down"
                            placeholder="Select"
                            isSearchable={false}
                            isDisabled={true}
                            // isDisabled={
                            //   userRoleData[1].label === this.state.roleId
                            //     ? disabledBtn
                            //     : userRoleData[2].label === this.state.roleId
                            //     ? disabledBtn
                            //     : userRoleData[3].label === this.state.roleId
                            //     ? disabledBtn
                            //     : false
                            // }
                        />
                        </FormGroup>
                    </Col>

                    
                    </Form>

                    <FormGroup className="list-btns">
                        <Button
                        className="btn custom-grey-btn cancel-btn"
                        onClick={this.handleResetBtn}
                        disabled={
                            this.state.campStatus === "0"
                            ? true
                            : userRoleData[1].label === this.state.roleId
                            ? disabledBtn
                            : userRoleData[2].label === this.state.roleId
                            ? disabledBtn
                            : userRoleData[3].label === this.state.roleId
                            ? disabledBtn
                            : false
                        }
                        >
                        Reset
                        </Button>
                        <Button
                        className={`btn custom-theme-btn save-btn`}
                        disabled={
                            this.state.campStatus === "0"
                            ? true
                            : userRoleData[1].label === this.state.roleId
                            ? disabledBtn
                            : userRoleData[2].label === this.state.roleId
                            ? disabledBtn
                            : userRoleData[3].label === this.state.roleId
                            ? disabledBtn
                            : false
                        }
                        onClick={() => this.handleSubmit()}
                        >
                        Save
                        </Button>
                    </FormGroup>

                </Col>
                </Row>
            </Col>
            </Row>
            {
                this.state.modal ? (
                    <PreviewImage
                        isModal={this.state.modal}
                        toggle={this.toggle}
                        patientId={this.state.patientId}
                        patientName={this.state.patientName}
                        previewItem={this.state.previewItem}
                        previewItemAll={this.state.previewItemAll}
                        selectPreMedia={this.state.mediaPreSelect}
                        selectPostMedia={this.state.mediaPostSelect}
                        quadrant={this.state.quadrant}
                        diagnosisType={this.state.diagnosisType}
                        ImgdilationType={this.state.ImgdilationType}
                        data={(item) => this.handlePreviewData(item)}
                        reloadPre={() => {return this.state.mediaPreSelect}}
                        handleFileUploadMulti={(data, dilationType, diagnosisType) => {
                            return this.handleFileUploadMulti(
                                data,
                                dilationType,
                                diagnosisType
                                )
                        }
                            
                        }
                        discardItem={(item, message) => this.discardItem(item, message)}
                        updateItem={(item, newItem) => this.updateItem(item, newItem)}
                        refreshList={(item) => this.refreshList(item)}
                        discardSelectMediaItem={(item) => this.discardSelectMediaItem(item)}
                        isDisplayBtn={
                        this.state.diagnosisType === applicationConstants.optoRE ||
                        this.state.diagnosisType === applicationConstants.optoLE
                            ? true
                            : false
                        }
                        duplicateItem={this.state.isDuplicateImg}
                        isDuplicateItem={(item, isUpdate) =>
                        this.isDuplicateItem(item, isUpdate)
                        }
                        isDiscard={this.state.isDiscard}
                    />
                    ) : null
                }
                { this.state.isConfirmation ? (
                    <ConfirmationBackArrow
                        isModal={this.state.isConfirmation}
                        handleCancelBtn={this.handleCancelBtn}
                        handleConfirmBtn={this.handleConfirmBtn}
                    /> ) : null
                }
                {
                    this.state.isQuickHelp ? (
                        <QuickHelpMessage
                            isModal={this.state.isQuickHelp}
                            isIcon={false}
                            data={DiagnosisAbbrevationInfo}
                            toggle={this.handleQuickHelp}
                        />
                    ) : null}
                    {this.state.isLoading ? <Loader isLoader={false} /> : null
                }
            </Strip>
        );
    }
}
export default withRouter(Diagnosis);
