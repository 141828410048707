import React from "react";
import { withRouter } from "react-router";
import { userRoleData } from "../../utils/dropdownData";
import AllPatientList from "../PatientDashboard/AllPatientList";
import ReaderPatientList from "../PatientDashboard/ReaderPatientList";

class PatientList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roleId: "",
    };
  }
  async componentDidMount() {
    const roleId = await localStorage.getItem("roleId");
    if (roleId) {
      this.setState({
        roleId: roleId,
      });
    }
  }



  render() {
    return (
      <div>
            {this.state.roleId === userRoleData[4].label ||
            this.state.roleId === userRoleData[5].label ||
            this.state.roleId === userRoleData[6].label ? (
              <ReaderPatientList />
            ) : (
              <AllPatientList />
            )}
      </div>
    );
  }
}

export default withRouter(PatientList);
