import React from "react";
import { Row, Col, Modal, ModalBody } from "reactstrap";
import Strip from "../Strip/Strip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

function QuickHelpMessage(props) {
  return (
    <Strip
      id="tst-camp-list"
      className="strip strip-no-padding"
      containerType="container-fluid"
    >
      <Row>
        <Col xs={10} sm={10} md={12} lg={12} xl={12}>
          <Modal
            isOpen={props.isModal}
            toggle={props.toggle}
            className="modal-container modal-lg modal-dialog-centered"
          >
            <ModalBody className="pd-12px">
              <Row className="no-margin">
                <Col xs={12} ms={12} md={12} lg={12} xl={12} className="m-auto">
                  <Row className="no-margin">
                    <Col
                      xs={12}
                      sm={12}
                      md={11}
                      lg={11}
                      xl={11}
                      className="m-auto px-0"
                    >
                      <Row className="no-margin">
                        {props.data.map((item) => (
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            className="px-0 pt-2 pb-2"
                          >
                            <Row>
                              <Col xs={5} sm={5} md={5} lg={3} xl={3} className="p-0">
                                {props.isIcon === true ? (
                                  <FontAwesomeIcon
                                    icon={faCircle}
                                    color={item.colorCode}
                                    size="2x"
                                  />
                                ) : (
                                  // <p>{item.icon}</p>
                                  <p className="mb-0 internal-section__title title-theme-color qucik-title p-0">
                                    {item.label}
                                  </p>
                                )}
                              </Col>
                              {/* <Col
                                xs={1}
                                sm={1}
                                md={1}
                                lg={1}
                                xl={1}
                                className="no-mobile--padding"
                              >
                                <p className="mb-0 internal-section__title title-charcoal-grey sh-divider fw-800">
                                  {`:`}
                                </p>
                              </Col> */}
                              <Col xs={6} sm={6} md={6} lg={8} xl={8} className="p-0">
                                <p className="mb-0 internal-section__title title-charcoal-grey sh-desc">
                                  {item.value}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </Col>
      </Row>
    </Strip>
  );
}

export default QuickHelpMessage;
