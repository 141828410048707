
const dataTablesStyledTheme = {
    table: {
      style: {
        border: "1px solid #dddddd",
      },
    },
    rows: {
      style: {
        fontSize: "0.85rem",
        minHeight: "2.25rem",
        fontFamily: "OpenSans-SemiBold",
        "&:hover": {
          color: "#72d389",
          cursor: "pointer",
        },
      },
      stripedStyle: {
        '&:nth-child(odd)': {
          color: '#000',
          backgroundColor: '#ddd',
        },
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "0px",
        borderTopColor: "transparent",
        minHeight: "2.5rem",
      },
    },
    headCells: {
      style: {
        paddingLeft: "0.45rem", // override the cell padding for head cells
        paddingRight: "0.45rem",
        fontSize: "1rem",
        backgroundColor: "#72d389",
        color: "#fff",
        minHeight: '2rem',
        fontFamily: "OpenSans-SemiBold",
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#dddddd",
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "0.45rem", // override the cell padding for data cells
        paddingRight: "0.45rem",
        "&:hover": {
          cursor: "pointer",
        },
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#dddddd",
        },
      },
    },
    pagination: {
      style: {
        color: '#000',
        fontSize: '0.85rem',
        backgroundColor: 'tranparent',
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        fontFamily: "OpenSans-SemiBold",
        borderTopColor: 'transparent',
      },
      pageButtonsStyle: {
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        padding: '8px',
        margin: '1px',
        cursor: 'pointer',
        transition: '0.4s',
        color: '#fff',
        fill: '#fff',
        backgroundColor: '#72d389',
        '&:disabled': {
          opacity: '0.4',
          cursor: 'unset',
          color: '#fff',
        },
      },
    },
  };
  


const dataTablesMobileStyledTheme = {
  table: {
    style: {
      border: "1px solid #dddddd",
    },
  },
  rows: {
    style: {
      fontSize: "0.85rem",
      fontFamily: "OpenSans-SemiBold",
      minHeight: '3rem',
      "&:hover": {
        color: "#72d389",
        cursor: "pointer",
      },
    },
    stripedStyle: {
      '&:nth-child(odd)': {
        color: '#000',
        backgroundColor: '#ddd',
      },
    },
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "0px",
      borderTopColor: "transparent",
      minHeight: '3rem',
    },
  },
  headCells: {
    style: {
      paddingLeft: "0.45rem", // override the cell padding for head cells
      paddingRight: "0.45rem",
      fontSize: "1rem",
      backgroundColor: "#72d389",
      color: "#fff",
      fontFamily: "OpenSans-SemiBold",
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dddddd",
      },
    },
  },
  cells: {
    style: {
      paddingLeft: "0.45rem", // override the cell padding for data cells
      paddingRight: "0.45rem",
      "&:hover": {
        cursor: "pointer",
      },
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#dddddd",
      },
    },
  },
  pagination: {
    style: {
      color: '#000',
      fontSize: '0.85rem',
      backgroundColor: 'tranparent',
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      fontFamily: "OpenSans-SemiBold",
      borderTopColor: 'transparent',
    },
    pageButtonsStyle: {
      borderRadius: '50%',
      height: '30px',
      width: '30px',
      padding: '4px',
      margin: '1px',
      cursor: 'pointer',
      transition: '0.4s',
      color: '#fff',
      fill: '#fff',
      backgroundColor: '#72d389',
      '&:disabled': {
        opacity: '0.4',
        cursor: 'unset',
        color: '#fff',
      },
    },
  },
};

export {
dataTablesMobileStyledTheme,
dataTablesStyledTheme,
};

